import React from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
  Alert, Row, Col,
} from "reactstrap";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import wheel from "assets/img/spin-the-wheel.png";
import { calculateTotalMoney } from "../../utils/helper";
import logo_transparent from "../../assets/img/logo_transparent.png";
import { userService } from "services/user.service";
import { SignupBonus } from "utils/constants";
import { ReferralBonus } from "utils/constants";
import SpinWheelDialog from "../../views/index-sections/SpinWheelDialog";


function IndexNavbar({message1, message2, animate=true}) {
  const navigate = useNavigate();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = React.useState(userService.getLocalStorageData());
  const userLevel = ('level' in userData && userData['level'] > 0) ? userData['level'] : 1;
  const userEmail = ('email' in userData) ? userData['email'] : '';

  const [isLoggedIn, setIsLoggedIn] = React.useState(userService.isLoggedIn());
  const [showBonusPopup, setShowBonusPopup] = React.useState(true);
  const [bonusClicked, setBonusClicked] = React.useState(false);
  const [showWheel, setShowWheel] = React.useState(false);

  const handleLoginClick = (event) => {
    event.preventDefault();
    document.getElementById('sliderHeaderId').classList.remove('moveslider');
    navigate({ pathname: '/login', search: searchParams.toString() });
  }

  const handleSignupClick = (event) => {
    event.preventDefault();
    document.getElementById('sliderHeaderId').classList.add('moveslider');
    navigate({ pathname: '/signup', search: searchParams.toString() });
  }

  const handleLogout = (event) => {
    event.preventDefault();
    userService.logout();
    setIsLoggedIn(false);
    navigate('/');
  }

  const handleProfileButton = (event) => {
    event.preventDefault();
    navigate('/profile', { state: { 'activeTab': 0 } });
  }

  const handleSettingButton = (event) => {
    event.preventDefault();
    navigate('/profile', { state: { 'activeTab': 1 } });
  }

  const handleNavLinkClick = (toPage) => {
    setCollapseOpen(false);
    navigate({ pathname: toPage, search: searchParams.toString(), state: location.state });
  }

  const notifyForSignupBonus = () => {
    return (
        <div className="game-popup">

            <Alert color="success" isOpen={showBonusPopup && !bonusClicked} >
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons sport_trophy"></i>
                    </div>
                    {' '}<a className="alert-link" style={{ fontSize: "large", cursor: "pointer" }} onClick={handleSignupClick}>Join Now and Get ₹{SignupBonus} Signup Bonus</a>
                    <button
                        type="button"
                        className="close"
                        onClick={(e) => {setBonusClicked(true);setShowBonusPopup(false)}}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
        </div>
    )
}

  React.useEffect(() => {
    try {
      userService.writeSearchParams(searchParams);
    } catch (err) {}

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        if ( animate) {
          setNavbarColor("navbar-transparent");
        } else {
          setNavbarColor("");
        }

      }
    };

    // setTimeout(function () {
    //   setShowBonusPopup(!isLoggedIn);
    // }, 3000);

    const updateUserData = () => {
      // When local storage changes, dump the list to
      // the console.
      setUserData(userService.getLocalStorageData());
    };
    
    window.addEventListener("scroll", updateNavbarColor);
    window.addEventListener("storage", updateUserData);
    
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
      window.removeEventListener("storage", updateUserData);
    };
  });

  const handlePromotionClick = (e) => {
    e.preventDefault();
    navigate({ pathname: "/promotions", search: searchParams.toString(), state: location.state });
  }

  const handleWheelClick = () => {
    setShowWheel(true);
  };

  const spinTheWheel = () => {
    return <div style={{display: 'flex', alignItems: 'center'}}>
      {/* Spin Wheel Image */}
      <img
          src={wheel}
          alt="Spin the Wheel"
          style={{
            width: '30px',
            height: '30px',
            cursor: 'pointer',
          }}
          onClick={handleWheelClick}
      />
    </div>
  }
  return (
      <>
        {collapseOpen ? (
            <div
                id="bodyClick"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(false);
                }}
            />
        ) : null}
        <Navbar className={"fixed-top " + navbarColor} expand="lg" color="header-info">
          <Container>
            <div className="navbar-translate" style={{marginTop: "20px", width: "auto"}}>
              <button
                  className="navbar-toggler navbar-toggler"
                  onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              aria-label={collapseOpen ? "Close navigation menu" : "Open navigation menu"}  // Added aria-label for accessibility
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >

              <img
                  src={logo_transparent}
                  width='90'
                  height='90'
                  alt="Con10Craze logo"
                  loading="lazy"
                  decoding="async"
              />
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              1st Fantasy Sports with Real Creators & Influencers
            </UncontrolledTooltip>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="/fantasy-games"
                  onClick={() => handleNavLinkClick('/fantasy-games')}>
                  <p style={{ color:"black", fontWeight:"bold",cursor: "pointer" }}>Fantasy Games</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/promotions"
                  onClick={() => handleNavLinkClick('/promotions')}>
                  <p style={{ color:"black", fontWeight:"bold",cursor: "pointer" }}>Promotions</p>
                </NavLink>
              </NavItem>

              {userService.isLoggedIn() && <NavItem>
                <NavLink
                    href="/how-to"
                    onClick={() => handleNavLinkClick('/how-to')}>
                  <p style={{ color:"black", fontWeight:"bold",cursor: "pointer" }}>How To</p>
                </NavLink>
              </NavItem>}

              <NavItem>
              <NavLink
                  href="/faqs"
                  onClick={() => handleNavLinkClick('/faqs')}>
                  <p style={{ color:"black", fontWeight:"bold",cursor: "pointer" }}>FAQ</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://www.instagram.com/con10craze?ref=webPortal"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i  style={{background: "linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)"}} className="fab fa-instagram"></i>
                  <p style={{ color:"#000000", fontWeight:"bold",}} className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com/con10craze?ref=webPortal"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i style={{color:"#1DA1F2"}} className="fab fa-twitter"></i>
                  <p style={{  color:"black", fontWeight:"bold"}}className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem className="mr-1">
                <NavLink
                  href="https://www.facebook.com/con10craze.official?ref=webPortal"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i style={{color:"#1877F2"}} className="fab fa-facebook-square"></i>
                  <p style={{  color:"black", fontWeight:"bold"}}className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>

          {showBonusPopup &&
              <div className="game-popup-header" onClick={handlePromotionClick}>
                <div className="promotion-message-container">
                  {/*<div className="alert-icon">*/}
                  {/*    <i className="now-ui-icons sport_trophy"></i>*/}
                  {/*</div>*/}
                  {message1 && <div  className="promotion-message message1">
                    {message1}
                  </div>
                  }
                  {!message1 && <div  className="promotion-message message1">
                    Sign up now and win ₹{SignupBonus} cash bonus instantly.
                  </div>
                  }

                  {message2 && <div  className="promotion-message message2">
                    {message2}
                  </div>
                  }
                  {!message2 && <div  className="promotion-message message2">
                   Refer your friends and both of you will earn ₹{ReferralBonus}.
                  </div>
                  }
                </div>
              </div>

          }

          {!isLoggedIn &&
            <div>
              <div className="sliderHeader" id="sliderHeaderId"></div>
              <div className="btnHeader">
                <button className="loginHeader" onClick={handleLoginClick}>LOG IN</button>
                <button className="signupHeader" onClick={handleSignupClick}>SIGN UP</button>
              </div>
            </div>
          }

          {isLoggedIn &&
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "white" }}>

              <i className="now-ui-icons icon-wallet-43"></i>
              {spinTheWheel()}

              <div
                  style={{display: "flex", alignItems: "center", cursor: "pointer"}}
                  onClick={() => navigate('/profile', {state: {'activeTab': 0}})}
              >
                <CurrencyRupeeIcon style={{color: "black"}}/>
                  <span style={{fontWeight: "bold", color: "black"}}>
                    {calculateTotalMoney(userData)}
                  </span>
              </div>

              <UncontrolledDropdown>
                <DropdownToggle
                    caret
                    color="black"
                    nav
                    className="custom-dropdown-toggle"

                    onClick={(e) => e.preventDefault()}
                >
                  <img src={userData.profile_img} style={{ border:"2px solid #dddddd",borderRadius: "999px"}} width="30" height="30" />
                </DropdownToggle >
                <DropdownMenu   right={true} style={{  boxShadow:" 0 4px 8px rgba(0, 0, 0, 0.1)", border:"2px solid #dddddd",}}>
                  {/* New WhatsApp Join Community Item */}
                  {/* New WhatsApp community link */}
                  <DropdownItem>
                    <a href="https://chat.whatsapp.com/H4XocOZ6kPHJdSqbMQ7Nwr" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'underline', color: 'green' }}>
                      <WhatsAppIcon style={{ marginRight: '8px', color: 'green' }} />
                      Join WhatsApp Community
                    </a>
                  </DropdownItem>
                  <DropdownItem onClick={handleSettingButton}>
                    <i className="now-ui-icons objects_diamond mr-1"
                      style={{
                        fontWeight: "bolder",
                        color: "#FFD700"}}></i>
                    Level {userLevel}
                  </DropdownItem>

                  <DropdownItem onClick={handleProfileButton} >
                    <i className="now-ui-icons users_single-02 mr-1"></i>
                    Profile
                  </DropdownItem>
                  <DropdownItem onClick={handleSettingButton}>
                    <i className="now-ui-icons ui-1_send mr-1"></i>
                    Refer
                  </DropdownItem>

                  <DropdownItem onClick={handleSettingButton}>
                    <i className="now-ui-icons ui-1_settings-gear-63 mr-1"></i>
                    Settings
                  </DropdownItem>
                  <DropdownItem onClick={handleLogout}>
                    <i className="now-ui-icons media-1_button-power mr-1"></i>
                    Sign Out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

            </div>}
        </Container>

      </Navbar>
      {isLoggedIn && <SpinWheelDialog showSpinWheelDialog={showWheel} setShowSpinWheelDialog={setShowWheel} />}
    </>
  );
}

export default IndexNavbar;
