import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { authHeader } from '../utils/auth-header';
import { baseUrl, defaultProfileImage } from '../utils/constants';

export const userService = {
    // General APIs
    contactus,
    forgotPassword,
    getEarlyAccess,
    getBlogs,
    getBlogData,
    getTotalRegisteredCount,
    getVIPRoomStats,
    verifyEmail,
    // Signup/Login APIs
    isLoggedIn,
    getLocalStorageData,
    getLastStreakTime,
    updateLastStreakTime,
    writeSearchParams,
    getSearchParamsKeyFromCookie,
    getStreakCross,
    updateStreakCross,
    getWhatsappCross,
    updateWhatsappCross,
    getStreakAdminView,
    spinTheWheel,
    saveAdWatched,
    getSpinStatus,
    getTodayAdCount,
    updateUserSpinWheelAdsStats,
    getUserSpinWheelAdsStats,
    saveMobileNumber,
    signup,
    verifySignupOTP,
    login,
    loginG,
    loginGCallback,
    completeLoginGoogleUser,
    logout,
    updatePersonalInfo,
    changePassword,
    resetPassword,
    setNewPassword,
    getStats,
    // Pack APIs
    joinTheDrop,
    leavePackQueue,
    leaveWaitingRoom,
    getPackDetails,
    loadPackDetailsForUser,
    buyPack,
    getAllPacks,
    getTopPacks,
    getUserPacks,
    openUserPack,
    fetchPackOrder,
    notifyArtistLive,
    notifyPackLive,
    revealMoment,
    // User details APIs
    getUserPackQueueDetails,
    getUserPackOrders,
    getUserProcessedPackOrders,
    getUserCinematics,
    getCinematicDetails,
    getMyRewards,
    getUserWalletDetails,
    withdrawMoney,
    withdrawAffiliateMoney,
    depositMoney,
    getMyTx,
    getMyStreaks,
    updateTodayStreakRewards,
    getUserPublicProfile,
    cancelUserTx,
    // Marketplace APIs
    marketplaceEarlyAccess,
    buyListing,
    cancelListing,
    getActiveListings,
    getTransactionStatus,
    listItem,
    getMomentsForPack,
    // Games
    getActiveGame,
    submitGameResponse,
    getLeaderboard,
    // Events
    getEvents,
    participateEvent,
    // Fantasy Games
    getFantasyGames,
    getFantasyArtists,
    getFantasyGameDetails,
    getFantasyGameCreatorsDetails,
    getPlayersLeaderboard,
    makeFantasyGamePayment,
    fantasyGamerVerifyPayment,
    verifyCashfreePayment,
    verifyGameCashfreePayment,
    submitFantasyGameTeam,
    getFantasyUserHistory,
    getFantasyUpcomingGames,
    getFantasyCompletedGames,
    getFantasyLiveGames,
    getFantasyPrivateGames,
    getUserPayments,
    acceptUserPayment,
    warnUserPayment,
    rejectUserPayment,
    cancelUserPayment,
    updateUserGameFeedback,
    getGamesStats,
    getTrendingStats,
    getCoinStats,
    getGameCreatorsEngagement,
    // Admin
    acceptUserTx,
    warningUserTx,
    failedUserTx,
    getAdminView,
    getAdminUserTxs,
    // Artist
    getArtistInsights,
    getArtistMgmtInsights,
    getCreatorDetails,
    // Affiliate
    getAffiliateStats,
    createPrivateContest,
    getInstagramPostThumbnail
};

function handleError(error) {
    var errorMessage = null;
    var status = 500;
    if (error.response) {
        if ( error.response.status ) {
            status = error.response.status
        }
        if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        } else if (error.response.data) {
            errorMessage = error.response.data;
        } else {
            errorMessage = error.message;
        }
    } else if (error.request) {
        errorMessage = error.request.response;
    } else {
        errorMessage = error.message;
    }
    const errorException = new Error(errorMessage);
    errorException.code = status
    throw errorException
}

async function getTotalRegisteredCount() {
    try {
        const response = await axios.get(baseUrl + "get_total_registered_count");
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getEarlyAccess(data) {
    try {
        const response = await axios.post(baseUrl + "get_early_access", data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getVIPRoomStats(data) {
    try {
        const response = await axios.post(baseUrl + "get_vip_room_stats", data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function contactus(data) {
    try {
        const response = await axios.post(baseUrl + "contact_us", data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getBlogs() {
    try {
        const response = await axios.get(baseUrl + "blogs/get_blogs");
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getBlogData(public_name) {
    try {
        const response = await axios.get(baseUrl + "blogs/" + public_name);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

function verifyEmail(token) {
    return axios.post(baseUrl + "verify_email/" + token)
    .then(result => {
        saveUserData(result);
    })
    .catch(error => {
        handleError(error);
    });
}

async function signup(data) {
    try {
        const response = await axios.post(baseUrl + "signup", data, { headers: authHeader() });
        return response.data;
    } catch(error) {
        handleError(error);
    }
}

function saveUserData(result) {
    if (result.status === 200) {
        var user = {};
        user.authdata = result.data.token;
        user.role = result.data.role;
        user.whitelisted = result.data.whitelisted;
        user.joined_at = result.data.joined_at;
        user.email = result.data.email;
        user.uuid = result.data.uuid;
        user.referral_link = result.data.referral_link;
        user.user_upi_id = result.data.user_upi_id;
        user.mobile_number = result.data.mobile_number;
        user.account_unutilized = result.data.account_unutilized;
        user.bonus = result.data.bonus;
        user.winnings = result.data.winnings;
        user.coins = result.data.coins;
        user.level = result.data.level;

        if (result.data.first_name === undefined || result.data.first_name === null) {
            user.first_name = '';
        } else {
            user.first_name = result.data.first_name;
        }
        if (result.data.last_name === undefined || result.data.last_name === null) {
            user.last_name = '';
        } else {
            user.last_name = result.data.last_name;
        }
        if (result.data.insta_handle === undefined || result.data.insta_handle === null) {
            user.insta_handle = '';
        } else {
            user.insta_handle = result.data.insta_handle;
        }

        if (result.data.profile_img === undefined || result.data.profile_img === null) {
            user.profile_img = defaultProfileImage;
        } else {
            user.profile_img = result.data.profile_img;
        }
        user.time = Date.now();
        localStorage.setItem('con10crazeuser', JSON.stringify(user));
    }
    else {
        // eslint-disable-next-line no-throw-literal
        throw ("Login failed with error " + result.status);
    }
}

function updateLocalStorage (result)  {
    if (result.status === 200) {
        var user_data = {}
        user_data.account_unutilized = result.data.account_unutilized;
        user_data.bonus = result.data.bonus;
        user_data.winnings = result.data.winnings;
        const localData = {
            ...JSON.parse(localStorage.getItem('con10crazeuser')),
            ...user_data
        };
        localStorage.setItem('con10crazeuser', JSON.stringify(localData));
    } else {
        throw ("Could not update user data " + result.status);
    }
}

function verifySignupOTP(data) {
    return axios.post(baseUrl + "verify_signup", data)
        .then(result => {
            saveUserData(result);
        })
         .catch(error => {
            handleError(error);
        });
}

function login(data) {
    return axios.post(baseUrl + "login", data)
        .then(result => {
            saveUserData(result);
            window.dispatchEvent(new Event('storage')); // Notify others about the login
        })
         .catch(error => {
            handleError(error);
        });
}

async function loginG(redirect_to, userType, referralId, instagramHandle) {
    try {
        const response = await axios.post(baseUrl + "g/login?redirect_to=" + redirect_to + "&userType=" + userType 
            + "&referralId=" + referralId + "&instagramHandle=" + instagramHandle);
        return response.data;
    } catch(error) {
        handleError(error);
    }
}

function loginGCallback(code, state) {
    return axios.get(baseUrl + "g/login/callback?code=" + code + "&state=" + state)
    .then(result => {
        saveUserData(result);
    })
    .catch(error => {
        handleError(error);
    });
}

function completeLoginGoogleUser(data) {
    return axios.post(baseUrl + "complete_login_google_user", data)
    .then(result => {
        saveUserData(result);
    })
     .catch(error => {
        handleError(error);
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('con10crazeuser');
    localStorage.removeItem('con10crazemetadata');
    window.dispatchEvent(new Event('storage')); // Notify others about the logout
}

function isLoggedIn() {
    if ( !localStorage.getItem('con10crazeuser') ) {
        return false;
    }
    const local_user_json = JSON.parse(localStorage.getItem('con10crazeuser'));
    if ( !local_user_json ) {
        return false;
    }
    const start_time = local_user_json.time;
    const end_time = new Date(start_time + 1000*60*60*24).getTime() //24 hours valid token for login
    const current_time = Date.now()
    if( end_time <= current_time ) {
        return false;
    }
    return true;
}

function getLocalStorageData() {
    return isLoggedIn() ? JSON.parse(localStorage.getItem('con10crazeuser')): {};
}

async function updatePersonalInfo(formData) {
    try {
        let urlHeaders = authHeader();
        urlHeaders['content-type'] = 'multipart/form-data';
        const response = await axios.post(baseUrl + "/update_personal_info", formData, { headers: urlHeaders });
        saveUserData(response);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserWalletDetails() {
    try {
        const response = await axios.get(baseUrl + "get-wallet-details", { headers: authHeader() });
        updateLocalStorage(response);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function forgotPassword(data) {
    try {
        const response = await axios.post(baseUrl + "forgot_password", data);
        return response.data;
    } catch(error) {
        handleError(error);
    }
}

async function resetPassword(token) {
    try {
        const response = await axios.post(baseUrl + "reset_password/" + token);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function setNewPassword(data) {
    try {
        const response = await axios.post(baseUrl + "set_new_password", data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function changePassword(data) {
    try {
        const response = await axios.post(baseUrl + "change_password", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getStats() {
    try {
        const response = await axios.get(baseUrl + "get_stats");
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getPackDetails(pack_external_id) {
    try {
        const response = await axios.get(baseUrl + "packs/" + pack_external_id);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function loadPackDetailsForUser(pack_external_id) {
    try {
        const response = await trackPromise(axios.get(baseUrl + "packs/load/" + pack_external_id, { headers: authHeader() }));
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function joinTheDrop(pack_external_id) {
    try {
        const response = await axios.post(baseUrl + "packs/join_pack_drop", {'pack_external_id': pack_external_id}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function leavePackQueue(pack_external_id) {
    try {
        const response = await axios.post(baseUrl + "packs/leave_pack_queue", {'pack_external_id': pack_external_id}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function leaveWaitingRoom(pack_external_id) {
    try {
        const response = await axios.post(baseUrl + "packs/leave_pack_waiting_room", {'pack_external_id': pack_external_id}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function buyPack(data) {
    try {
        const response = await axios.post(baseUrl + "pack/order/buy", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getAllPacks() {
    try {
        const response = await axios.get(baseUrl + "packs/get_all_packs", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getTopPacks() {
    try {
        const response = await axios.get(baseUrl + "packs/get_all_packs?size=3&page=0", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserPacks(data) {
    try {
        const response = await axios.get(baseUrl + "pack/order/my_orders", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function openUserPack(pack_order_external_id) {
    try {
        const response = await axios.post(baseUrl + "pack/order/" + pack_order_external_id + "/open", {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getCinematicDetails(cinematic_external_id) {
    try {
        const response = await axios.get(baseUrl + "cinematics/" + cinematic_external_id, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserPackQueueDetails(pack_external_id) {
    try {
        const response = await axios.get(baseUrl + "packs/get_user_queue_details/" + pack_external_id, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserPackOrders() {
    try {
        const response = await axios.get(baseUrl + "pack/order/my_orders", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserProcessedPackOrders() {
    try {
        const response = await axios.get(baseUrl + "pack/order/my_orders?status=PROCESSED", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getMyRewards() {
    try {
        const response = await axios.get(baseUrl + "get_my_rewards", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserCinematics() {
    try {
        const response = await axios.get(baseUrl + "cinematics/my_cinematics", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function fetchPackOrder(order_external_id, with_items=false) {
    try {
        const response = await axios.get(baseUrl + "pack/order/" + order_external_id + "?with_items=" + with_items, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function revealMoment(data) {
    try {
        const response = await axios.post(baseUrl + "pack/order/reveal_cinematic", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function cancelListing(data) {
    try {
        const response = await axios.post(baseUrl + "marketplace/cancel_item", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getActiveListings(variables) {
    try {
        const response = await axios.post(baseUrl + "marketplace/get_active_listings", variables);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function listItem(data) {
    try {
        const response = await axios.post(baseUrl + "marketplace/list_item", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function buyListing(data) {
    try {
        const response = await axios.post(baseUrl + "marketplace/buy_item", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getTransactionStatus(signatureId) {
    try {
        const response = await axios.post(baseUrl + "get_transaction_status", {'signature_id': signatureId}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getMomentsForPack(pack_external_id) {
    try {
        const response = await axios.get(baseUrl + "packs/moments/" + pack_external_id, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function submitGameResponse(data) {
    try {
        const response = await axios.post(baseUrl + "games/submit-response", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getActiveGame() {
    try {
        const response = await axios.get(baseUrl + "games/get-active-game", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getLeaderboard() {
    try {
        const response = await axios.get(baseUrl + "games/leaderboard", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function marketplaceEarlyAccess() {
    try {
        const response = await axios.post(baseUrl + "marketplace_early_access", {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function notifyPackLive(pack_external_id) {
    try {
        const response = await axios.post(baseUrl + "packs/notify_pack_live/" + pack_external_id, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function notifyArtistLive(artist) {
    try {
        const response = await axios.post(baseUrl + "packs/notify_artist_live/" + artist, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getEvents() {
    try {
        const response = await axios.get(baseUrl + "events/", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function participateEvent(eventId) {
    try {
        const response = await axios.post(baseUrl + "events/participate/" + eventId, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyGames() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-all-games", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyArtists() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-all-artists", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyGameDetails(game_external_id) {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-game-details/" + game_external_id, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyGameCreatorsDetails(game_external_id) {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-game-creators-details/" + game_external_id, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getGamesStats() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-games-stats", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getTrendingStats() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-trending-stats", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getPlayersLeaderboard(game_external_id) {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-players-leaderboard/" + game_external_id, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function makeFantasyGamePayment(game_external_id, selectedArtists, booster, totalPrice, accountUnutilizedTaken, bonusTaken, userUpiId, teamNumber, winningsTaken) {
    try {
        let data = {"team": selectedArtists, "booster": booster, "total_price": totalPrice,
        "account_unutil_taken": accountUnutilizedTaken, "bonus_taken": bonusTaken, "user_upi_id": userUpiId, "team_number": teamNumber, "winnings_taken": winningsTaken};
        const response = await axios.post(baseUrl + "fantasy-games/game/" + game_external_id + "/make-payment", data, { headers: authHeader() });
        updateLocalStorage(response);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function fantasyGamerVerifyPayment(razorPayRes) {
    try {
        const response = await axios.post(baseUrl + "fantasy-games/verify_payment", razorPayRes, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function verifyCashfreePayment(cashfreeOrderIdDict) {
    try {
        const response = await axios.post(baseUrl + "verify-cashfree-payment", cashfreeOrderIdDict, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function verifyGameCashfreePayment(cashfreeOrderIdDict) {
    try {
        const response = await axios.post(baseUrl + "fantasy-games/verify-cashfree-payment", cashfreeOrderIdDict, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function withdrawMoney(withdrawAmount, userUpiId) {
    try {
        let data = {"amount": withdrawAmount, "user_upi_id": userUpiId};
        const response = await axios.post(baseUrl + "withdraw-money", data, { headers: authHeader() });
        updateLocalStorage(response);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function withdrawAffiliateMoney(withdrawAmount, userUpiId) {
    try {
        let data = {"amount": withdrawAmount, "user_upi_id": userUpiId};
        const response = await axios.post(baseUrl + "withdraw-affiliate-money", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function depositMoney(depositAmount) {
    try {
        let data = {"amount": depositAmount};
        // Deposit money should update user account only after actual money is sent to con10craze UPI
        const response = await axios.post(baseUrl + "deposit-money", data, { headers: authHeader() });
//        updateLocalStorage(response);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getMyTx() {
    try {
        const response = await axios.get(baseUrl + "get-my-tx", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getMyStreaks() {
    try {
        const response = await axios.get(baseUrl + "get-my-streaks", { headers: authHeader() })
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

function getUserSpinWheelAdsStats() {
    const storedData = localStorage.getItem('con10crazeusertodayadsstats');
    const adData = storedData ? JSON.parse(storedData) : { lastWatched: null, adCount: 0 };
    return adData;
}

// Function to retrieve today's ad count from localStorage
function getTodayAdCount() {
    const storedData = localStorage.getItem('con10crazeusertodayadsstats');
    if (storedData) {
        const adData = JSON.parse(storedData);
        const lastWatchedDate = new Date(adData.lastWatched);
        const today = new Date();
        // Check if the last watched date is today
        if (
            lastWatchedDate.getFullYear() === today.getFullYear() &&
            lastWatchedDate.getMonth() === today.getMonth() &&
            lastWatchedDate.getDate() === today.getDate()
        ) {
            return adData.adCount;
        }
    }
    return 0; // Return 0 if no ads watched today
}



function updateUserSpinWheelAdsStats() {
    // Retrieve existing ad data from localStorage
    const storedData = localStorage.getItem('con10crazeusertodayadsstats');
    const adData = storedData ? JSON.parse(storedData) : { lastWatched: null, adCount: 0 };

    // Get the current date and time
    const now = new Date();

    // Check if the last watched date is today
    const lastWatchedDate = adData.lastWatched ? new Date(adData.lastWatched) : null;
    const isSameDay = lastWatchedDate &&
        lastWatchedDate.getFullYear() === now.getFullYear() &&
        lastWatchedDate.getMonth() === now.getMonth() &&
        lastWatchedDate.getDate() === now.getDate();

    // Update ad count: reset if it's a new day, otherwise increment
    const updatedAdCount = isSameDay ? adData.adCount + 1 : 1;

    // Create updated ad data object
    const updatedAdData = {
        lastWatched: now.toISOString(), // Store the current timestamp
        adCount: updatedAdCount
    };

    // Save the updated ad data back to localStorage
    localStorage.setItem('con10crazeusertodayadsstats', JSON.stringify(updatedAdData));
}


function getLastStreakTime() {
    return isLoggedIn() ? JSON.parse(localStorage.getItem('con10crazeuserlaststreaktime')): null;
}

function updateLastStreakTime(date) {
    localStorage.setItem('con10crazeuserlaststreaktime',JSON.stringify(date));
}

function getWhatsappCross() {
    return isLoggedIn() ? JSON.parse(localStorage.getItem('con10crazeuserwhatsappcross')): null;
}

function updateWhatsappCross(date) {
    localStorage.setItem('con10crazeuserwhatsappcross',JSON.stringify(date));
}

function getStreakCross() {
    return isLoggedIn() ? JSON.parse(localStorage.getItem('con10crazeuserstreakcross')): null;
}

function updateStreakCross(date) {
    localStorage.setItem('con10crazeuserstreakcross',JSON.stringify(date));
}

function writeSearchParams(searchParams) {
    if (searchParams === undefined || searchParams === null || searchParams.size === 0) {
        return;
    }
    searchParams = JSON.parse('{"' + searchParams.toString().replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
    if (Object.keys(searchParams).length === 0) {
        return;
    }
    let local_search_params = localStorage.getItem('con10crazesearchparams');
    if ( local_search_params ) {
        local_search_params = JSON.parse(localStorage.getItem('con10crazesearchparams'));
    } else {
        local_search_params = {};
    }
    for (let key in searchParams) {
        local_search_params[key] = searchParams[key];
    }
    localStorage.setItem('con10crazesearchparams', JSON.stringify(local_search_params));
}

function getSearchParamsKeyFromCookie(paramsKey) {
    try {
        let local_search_params = localStorage.getItem('con10crazesearchparams');
        if ( !local_search_params ) {
            return null;
        }
        local_search_params = JSON.parse(localStorage.getItem('con10crazesearchparams'));
        if (paramsKey in local_search_params && local_search_params[paramsKey]) {
            return local_search_params[paramsKey];
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
}

async function getStreakAdminView() {
    try {
        const response = await axios.get(baseUrl + "get-streak-admin-view", { headers: authHeader() })
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function updateTodayStreakRewards(streakDay) {
    try {
        let data = {};
        const response = await axios.post(baseUrl + "update-my-today-streak-rewards", data, { headers: authHeader() });
        if (response.status === 200) {
            var user_data = {}
            user_data.bonus = response.data.bonus;
            const localData = {
                ...JSON.parse(localStorage.getItem('con10crazeuser')),
                ...user_data
            };
            localStorage.setItem('con10crazeuser', JSON.stringify(localData));
        } else {
            throw ("Could not update user data " + response.status);
        }
    
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function submitFantasyGameTeam(game_external_id, teamDetails) {
    try {
        const response = await axios.post(baseUrl + "fantasy-games/game/" + game_external_id + "/submit-team", teamDetails, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyUserHistory() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-my-game-history", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyUpcomingGames() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-upcoming-games", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyCompletedGames() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-completed-games", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyLiveGames() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-live-games", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getFantasyPrivateGames() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-private-games", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getArtistInsights() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-artist-insights", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getArtistMgmtInsights() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-all-artists-insights-mgmt", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getCreatorDetails(social_media_handle) {
    try {
        const response = await axios.get(baseUrl + "creator/" + social_media_handle, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getAffiliateStats() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-affiliate-stats", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserPayments() {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/user-payments", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getAdminView() {
    try {
        const response = await axios.get(baseUrl + "admin-view", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getAdminUserTxs() {
    try {
        const response = await axios.get(baseUrl + "admin-user-txs", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function acceptUserPayment(fantasy_game_user_id, game_uuid, upi_tx_id) {
    try {
        const response = await axios.post(baseUrl + "fantasy-games/accept-user-payment/" + fantasy_game_user_id + "/" + game_uuid + "/" + upi_tx_id, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function warnUserPayment(fantasy_game_user_id, game_uuid) {
    try {
        const response = await axios.post(baseUrl + "fantasy-games/warn-user-payment/" + fantasy_game_user_id + "/" + game_uuid, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function rejectUserPayment(fantasy_game_user_id, game_uuid) {
    try {
        const response = await axios.post(baseUrl + "fantasy-games/reject-user-payment/" + fantasy_game_user_id + "/" + game_uuid, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function updateUserGameFeedback(cashfree_order_id, feedback) {
    try {
        const data = {'feedback': feedback}
        const response = await axios.post(baseUrl + "fantasy-games/update-user-game-feedback/"  + cashfree_order_id + "/", data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function cancelUserPayment(cashfree_order_id, game_uuid, error_reason) {
    try {
        const data = {'error_reason': error_reason}
        const response = await axios.post(baseUrl + "fantasy-games/cancel-user-payment/"  + cashfree_order_id + "/" + game_uuid, data, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function acceptUserTx(user_id, user_tx_id, amount) {
    try {
        const response = await axios.post(baseUrl + "accept-user-tx/" + user_id + "/" + user_tx_id + "/" + amount, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function warningUserTx(user_id, user_tx_id, amount) {
    try {
        const response = await axios.post(baseUrl + "warning-user-tx/" + user_id + "/" + user_tx_id + "/" + amount, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function failedUserTx(user_id, user_tx_id, amount) {
    try {
        const response = await axios.post(baseUrl + "failed-user-tx/" + user_id + "/" + user_tx_id + "/" + amount, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function cancelUserTx(cashfree_order_id) {
    try {
        const response = await axios.post(baseUrl + "cancel-user-tx/" + cashfree_order_id , {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function saveMobileNumber(formData) {
    try {
        let urlHeaders = authHeader();
        urlHeaders['content-type'] = 'multipart/form-data';
        const response = await axios.post(baseUrl + "/save_mobile_number", formData, { headers: urlHeaders });
        saveUserData(response);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function spinTheWheel(adsCount=0) {
    try {
        const response = await axios.post(baseUrl + "spin-result?adsCount=" + adsCount, {}, { headers: authHeader() });
        if (response.status === 200) {
            var user_data = {}
            user_data.bonus = response.data.user_bonus;
            user_data.coins = response.data.user_coins;
            const localData = {
                ...JSON.parse(localStorage.getItem('con10crazeuser')),
                ...user_data
            };
            localStorage.setItem('con10crazeuser', JSON.stringify(localData));
            window.dispatchEvent(new Event('storage')); // Notify others about the user data
        } else {
            throw ("Could not update user data " + response.status);
        }
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function createPrivateContest(contestDetails) {
    try {
        const response = await axios.post(baseUrl + "/fantasy-games/create-private-contest", contestDetails, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getCoinStats() {
    try {
        const response = await axios.get(baseUrl + "coin-stats", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getUserPublicProfile(user_uuid) {
    try {
        const response = await axios.get(baseUrl + "public-profile/" + user_uuid, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getInstagramPostThumbnail(thumbnail_url) {
    try {
        const data = {'thumb_url': thumbnail_url}
        const response = await axios.post(baseUrl + "fantasy-games/instagram-post-thumbnail", data, { headers: authHeader() });
        // Return the image Blob URL to be used as the src in the img tag
        const base64String = response.data.image;
        const mimeType = response.data.mime;
  
        // Convert base64 string to a Blob
        const byteCharacters = atob(base64String); // Decode base64 string to raw binary
        const byteArray = new Uint8Array(byteCharacters.length);
  
        // Convert the string to a byte array
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
  
        // Create a Blob from the byte array
        const blob = new Blob([byteArray], { type: mimeType });
  
        // Return the Blob URL
        return URL.createObjectURL(blob);
    } catch (error) {
        handleError(error);
    }
}

async function saveAdWatched() {
    try {
        const response = await axios.post(baseUrl + "save-ad-watched", {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getSpinStatus() {
    try {
        const response = await axios.get(baseUrl + "get-spin-status", { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

async function getGameCreatorsEngagement(game_uuid) {
    try {
        const response = await axios.get(baseUrl + "fantasy-games/get-game-creators-engagement/" + game_uuid , { headers: authHeader() });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}