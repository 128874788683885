import { format } from "date-fns";

// reactstrap components
import React, {useEffect} from "react";
import { Button, Col, Container, Row } from "reactstrap";
import MyModalDialog from "views/index-sections/MyModalDialog";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// core components
import games from "../../assets/img/game.webp";
import how_to_thumb from "assets/img/how_to_thumb.webp";
import { copyTextToClipboard } from "utils/helper";
import {useTimer} from "react-timer-hook";

function FantasyGameHeader({ game, topParticipants, title, url, shareGame }) {
    let pageHeader = React.createRef();
    const howToPlayVideoSrc = "https://d78eqrbdvopvl.cloudfront.net/how_to_play.mp4";
    const [showHowToPlay, setShowHowToPlay] = React.useState(false);
    const [showShare, setShowShare] = React.useState(shareGame ? shareGame : false);
    const social_hashtag = "con10craze";
    const social_body = "Try & beat me in the Con10Craze Fantasy Sports Game!";

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                if (pageHeader.current) {  // Ensure the element exists before accessing style
                    let windowScrollTop = window.pageYOffset / 3;
                    pageHeader.current.style.transform =
                        "translate3d(0," + windowScrollTop + "px,0)";
                }
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    const MyTimer = ({ expiryTimestamp, prefix }) => {
        const {
            seconds,
            minutes,
            hours,
            days,
            isRunning,
            start,
            pause,
            resume,
            restart
        } = useTimer({
            expiryTimestamp,
            autoStart: true, // Automatically start the timer when component mounts
        });

        // Optionally, you could use setInterval to ensure the timer continues to update at a second interval
        useEffect(() => {
            if (isRunning) {
                const interval = setInterval(() => {
                    // Force re-render every second to show updated time
                }, 1000);

                return () => clearInterval(interval); // Clean up the interval on component unmount or when not running
            }
        }, [isRunning]);

        return (
            <div>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
                {prefix}:&nbsp;
                {days > 0 && `${days}d `}
                {hours > 0 && `${hours}h `}
                {minutes > 0 && `${minutes}m `}
                {seconds}s
            </span>
            </div>
        );
    };


    const gameTimeAndUser = () => {
        var endDate = new Date(game["details"]["end_time"]);

        let users = game["details"]["max_users"];
        if (game["status"] !== "upcoming") {
            users = game["details"]["interested"];
        }
        let prizeDistribution = Math.floor(users * game["details"]["price"] * game["details"]["winners_users_percent"] / 100);
        return (
            <div style={{ marginTop: "4rem" }}>
                <Col  style={{marginTop:"100px"}}>
                    {game.status === "upcoming" &&
                        <h4 className="title center ml-auto mr-auto">{game["details"]["name"]}</h4>
                    }
                    {game.status === "upcoming" &&
                        <h5 className="center ml-auto mr-auto" style={{ marginBottom: "0px" }}>
                            <MyTimer expiryTimestamp={new Date(game["details"]["start_time"])} prefix={"Starts in"}></MyTimer></h5>
                    }
                    {game.status === "live" &&
                        <h3 className="center ml-auto mr-auto" style={{ marginBottom: "0px" }}><span className="title">{game["details"]["name"]}</span> is now LIVE!</h3>
                    }
                    {game.status === "completed" &&
                        <h3 className="center ml-auto mr-auto" style={{ marginBottom: "0px" }}><span className="title">{game["details"]["name"]}</span> has completed!</h3>
                    }
                    {game.status === "live" && game["details"]["end_time"] !== undefined &&
                        <p className="center ml-auto mr-auto" style={{fontWeight: "500"}}>
                            <h5 className="center ml-auto mr-auto" style={{marginBottom: "0px"}}>
                                <MyTimer
                                expiryTimestamp={endDate} prefix={"Ends in"}></MyTimer></h5>
                            {/*Ends at: {format(endDate, 'dd MMM h:mm a')}*/}
                        </p>
                    }
                    {game.status === "completed" && game["details"]["end_time"] !== undefined &&
                        <p className="center ml-auto mr-auto" style={{fontWeight: "500" }}>Ended at: {format(endDate, 'dd MMM h:mm a')}</p>
                    }

                    <h5 className="ml-auto mr-auto" style={{ fontWeight: "bold", color: "black", marginTop: "15px", marginBottom: "0px" }}>
                        Prize Pool: <span className="title" style={{color:"#B00610"}}>₹{prizeDistribution}</span></h5>
                    <h5 className="ml-auto mr-auto">Entry fee: <span style={{color:"#B00610"}}>₹{game["details"]["price"]}</span></h5>
                </Col>
            </div>
        )
    }

    const numberFromText = (text) => {
        // numberFromText("AA");
        const charCodes = text
          .split('') // => ["A", "A"]
          .map(char => char.charCodeAt(0)) // => [65, 65]
          .join(''); // => "6565"
        return charCodes;
      }

    const gamePlayers = () => {
        const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A', '#A456E3', 'red', 'purple', 'green', 'grey', 'magenta'];
        return (
            <div>
                {topParticipants.length > 0 &&
                    <Row>
                        <Col className="ml-auto mr-auto text-center">
                            {topParticipants.map((joinedUser, index) => {
                                let bgColor = colors[numberFromText(joinedUser["first_name"]) % colors.length]; // => "#DC2A2A"

                                if (index < 5) {
                                    let userProfileImg = joinedUser["profile_img"];
                                    if (userProfileImg === null || userProfileImg === undefined) {
                                        return (
                                            <span className="avatar">
                                                <div class="default-avatar" width="60px" height="60px" style={{ backgroundColor: bgColor}}>{joinedUser["first_name"][0]}</div>
                                            </span>
                                        )
                                    }
                                    else return (
                                        <span className="avatar">
                                            <img src={userProfileImg}
                                                width="60px" height="60px" />
                                        </span>
                                    )
                                }
                                else if (index == 5) {
                                    return (
                                        <span className="avatar" style={{ fontSize: "large" }}>
                                            <AddIcon width="60px" height="60px" fontSize="large"/>
                                            &nbsp;{topParticipants.length - 5} more
                                        </span>
                                    )
                                }
                            })
                            }
                            <p style={{ marginTop: "10px" }}>{topParticipants.length} Playing</p>
                        </Col>
                    </Row>}

            </div>
        )
    }

    const handleClipBoardClick = (e, data) => {
        e.preventDefault();
        copyTextToClipboard(data);
    }

    return (
        <>
            <div className="page-header page-header-xsmall">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: `url(${games})`
                    }}
                    ref={pageHeader}
                ></div>
                <div className="content-center" style={{marginTop:"0%"}}>
                    <Container>

                        {gameTimeAndUser()}

                        <MyModalDialog title="How to Play Con10Craze Fantasy Sports Games" show={showHowToPlay} onClose={() => setShowHowToPlay(false)}>
                            <video id="teaser_vid"
                                preload="none"
                                poster={how_to_thumb}
                                frameBorder="0"
                                playsInline
                                controls
                                width="100%"
                                title="How to Play Con10Craze Fantasy Sports"
                            >
                                <source src={howToPlayVideoSrc} type="video/mp4" />
                            </video>
                        </MyModalDialog>

                        <MyModalDialog title="Invite your friends" show={showShare} onClose={() => setShowShare(false)}>
                            <div className="text-center">

                                <p style={{ fontSize: "500" }}>Double your fun by competing with your friends!</p>


                                <div className="share-container" style={{
                                }}>
                                    <div className="share">
                                        <FacebookShareButton className='icon first react_share_button'
                                            url={url}
                                            title={social_body}
                                        >
                                            <FacebookIcon size={36} round={true} />
                                        </FacebookShareButton>

                                        <TwitterShareButton className='icon react_share_button'
                                            url={url}
                                            title={social_body}
                                            hashtags={[social_hashtag]}
                                        >
                                            <TwitterIcon size={36} round={true} />
                                        </TwitterShareButton>

                                        <LinkedinShareButton className='icon react_share_button'
                                            url={url}
                                        >
                                            <LinkedinIcon size={36} round={true} />
                                        </LinkedinShareButton>

                                        <RedditShareButton className='icon react_share_button'
                                            url={url}
                                            title={social_body}
                                        >
                                            <RedditIcon size={36} round={true} />
                                        </RedditShareButton>

                                        <WhatsappShareButton className='icon last react_share_button'
                                            url={url}
                                            title={social_body}
                                            separator=":: "
                                        >
                                            <WhatsappIcon size={36} round={true} />
                                        </WhatsappShareButton>
                                    </div>
                                    <Row style={{ marginTop: "20px" }}>
                                        <Col className="ml-auto mr-auto">
                                            <span style={{ fontWeight: "bold" }}>Or share the Game Link:<br></br></span>
                                            <span style={{ fontWeight: "bold", color: "rgb(5,99,193)", textDecorationLine: 'underline' }}>
                                                {url}
                                            </span>
                                            <ContentCopyIcon className="copy-icon" onClick={(e) => {
                                                handleClipBoardClick(e, url)
                                            }} style={{ cursor: "pointer", width: "auto", marginLeft: "10px", fontSize: "25px", }} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </MyModalDialog>

                        <a
                            className="howto_float"
                            onClick={() => setShowHowToPlay(true)}
                        >
                            <i className="now-ui-icons media-1_button-play"></i>
                        </a>

                        <a
                            className="share_float"
                            onClick={() => setShowShare(true)}
                        >
                            <i className="now-ui-icons arrows-1_share-66"></i>
                        </a>
                    </Container>
                </div>

            </div>
        </>
    );
}

export default FantasyGameHeader;
