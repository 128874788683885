import React, { useEffect, useRef, useState } from 'react';
import {Alert, Button, Col, Container, Row} from 'reactstrap';
import { userService } from 'services/user.service';
import { getCurrentDateInIST } from 'utils/helper';
import MyModalDialog from "./MyModalDialog";
import { Wheel } from "react-custom-roulette";

const data = [
    { option: "FEELING LUCKY", result: "Feeling lucky!" },               // Index 0
    { option: "₹20", result: "You won ₹20" },                        // Index 1 (INR Bonus)
    { option: "12 COINS", result: "You won 12 coins" },              // Index 2 (Coins)
    { option: "BETTER LUCK NEXT TIME", result: "Better luck next time!" }, // Index 3 (No reward)
    { option: "₹10", result: "You won ₹10" },                        // Index 4 (INR Bonus)
    { option: "FEELING LUCKY", result: "Feeling lucky!" },               // Index 5
    { option: "BETTER LUCK NEXT TIME", result: "Better luck next time!" }, // Index 6 (No reward)
    { option: "₹100", result: "You won ₹100" }                       // Index 7 (Very rare INR Bonus)
];

const backgroundColors = [
    "#F22B35",  // Red
    "#F99533",  // Orange
    "#24CA69",  // Green
    "#514E50",  // Grey
    "#46AEFF",  // Blue
    "#9145B7",  // Purple
    "#F5A623",  // Yellow
    "#4A90E2"   // Light Blue
];

const SpinTheWheel = () => {
    const [isLoggedIn] = React.useState(userService.isLoggedIn());
    const [spinResult, setSpinResult] = useState(-1);
    const [showSpinResult, setShowSpinResult] = useState(false);
    const [reward, setReward] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [showNoAdAlert, setShowNoAdAlert] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [timer, setTimer] = useState(0);
    const [timerActive, setTimerActive] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [errorHref, setErrorHref] = useState(null);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [adCount, setAdCount] = useState(0); // Number of ads watched today
    const [lastAdTime, setLastAdTime] = useState(null); // Timestamp of the last ad watched
    const [adTimer, setAdTimer] = useState(0); // Timer for the next available ad
    const [adTimerActive, setAdTimerActive] = useState(false); // Indicates if the ad timer is active
    const [showingAd, setShowingAd] = useState(false); // Indicates if the ad timer is active
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const adUnitRef = useRef(null);

    const updateAdsDataState = () => {
        const storedData = userService.getUserSpinWheelAdsStats();
        if (storedData) {
            const adData = typeof storedData === 'string' ? JSON.parse(storedData) : storedData;
            setAdCount(adData.adCount || 0);
            const lastAdTime = adData.lastWatched ? new Date(adData.lastWatched) : null
            setLastAdTime(lastAdTime);
            const now = new Date();
            const timeDiff = now - new Date(lastAdTime); // Difference in milliseconds
            const remainingTime = Math.max(600 - Math.floor(timeDiff / 1000), 0); // 600 seconds (10 minutes) minus the elapsed time
            if ( remainingTime> 0 ) {
                setAdTimer(remainingTime); // Set the timer to the remaining time
                setAdTimerActive(remainingTime > 0); // Activate the timer if there’s remaining time
            } else {
                setAdTimer(0); // If there's no `lastAdTime`, reset the timer
                setAdTimerActive(false); // Deactivate the timer
            }
        }else {
            setAdTimer(0); // If there's no `lastAdTime`, reset the timer
            setAdTimerActive(false); // Deactivate the timer
        }

    }


    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        if (hours > 0) {
            return `${hours}h ${minutes}m`;
        } else if (minutes > 0) {
            return `${minutes}m ${remainingSeconds}s`;
        } else {
            return `${remainingSeconds}s`;
        }
    };

    useEffect(() => {
        let interval;
        if (adTimerActive && adTimer > 0) {
            interval = setInterval(() => {
                setAdTimer((prev) => prev - 1);
            }, 1000);
        }else {
            setAdTimer(0)
            setAdTimerActive(false)
        }
        return () => clearInterval(interval);
    }, [adTimerActive, adTimer]);

    useEffect(() => {
        let interval;
        if (!mustSpin && timerActive && timer > 0) {
            interval = setInterval(() => {
              setTimer((prev) => prev - 1);
            }, 1000);
        } else if (!timerActive || !timer){
            setTimer(0)
            setTimerActive(false)
        }
        return () => clearInterval(interval);
    }, [timerActive, timer, mustSpin]);



    useEffect(() => {
        if ( !isLoggedIn) {
           return
        }
        userService.getSpinStatus()
        .then((res) => {
            // If spin is not allowed, calculate the time left
            if (!res['can_spin'] && res['reason'].includes("Wait until")) {
                const targetTime = new Date(res['reason'].split("Wait until ")[1]);
                const now = new Date();
                const nowIst = getCurrentDateInIST(now);
                const timeDiff = targetTime - nowIst
                if (timeDiff > 0) {
                    setTimer(Math.floor(timeDiff / 1000)); // Set timer in seconds
                    setTimerActive(true);
                }
            }
            updateAdsDataState()
        })
        .catch((err) => {
            if (typeof (err) !== 'object') {
                setErrMsg(err.message);
            } else {
                setErrMsg('Something went wrong, please try again in a bit.');
            }
            setShowAlert(true);
        })
    }, [])

    const showAd = () => {
        const adSlot = document.querySelector('#ad-slot');

        if (adSlot && !adSlot.innerHTML) { // Check if the ad slot is empty
            // Optionally clear the previous ad content
            adSlot.innerHTML = ''; // Clear any existing content in the ad slot

        } else {
//            console.log("Ad slot already contains an ad or is not available.");
        }

        // Push a new ad request to the ad slot
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    };
    const checkAdStatusWithRetry = (intervalTime = 2000, maxAttempts = 5) => {
        let attempts = 0;

        setShowSpinResult(false)
        const interval = setInterval(() => {
          // Query the ad element to check for the data-ad-status
          const adUnitElement = adUnitRef.current;
          if (adUnitElement) {
            const adStatus = adUnitElement.getAttribute('data-ad-status');
            if (adStatus) {
              clearInterval(interval); // Stop the interval once the status is found
              if (adStatus === 'unfilled') {
                // Ad unit was unfilled
                // Implement your logic for handling unfilled ads here
                  setShowNoAdAlert(true);
                  // Hide the alert after 5 seconds
                  setTimeout(() => {
                      setShowNoAdAlert(false);
                  }, 5000);  // 5000 milliseconds = 5 seconds
                return;
              }
              setShowingAd(true)
              // showAd()
              //spin for user
              handleSpin(userService.getTodayAdCount()+1)  
            }
          }
    
          // Stop checking after the specified number of attempts
          attempts++;
          if (attempts >= maxAttempts) {
            clearInterval(interval); // Stop the interval if max attempts are reached
          }
        }, intervalTime); // Retry every intervalTime (2 seconds by default)
      };

    const handleSpinWithAdCount = () => {
        //show ads
        showAd();
        checkAdStatusWithRetry();
    }

    const handleSpinWithoutAd = () => {
        handleSpin(0)
    }

    const handleSpin = (ads_count=0) => {
        if (!userService.isLoggedIn()) {
            setErrorMsg("Log in to get started...");
            setErrorHref("/login");
            setShowErrorDialog(true);
            // Set a timer to redirect after 5 seconds
            setTimeout(() => {
                window.location.href = "/login";
            }, 5000); // 5000 milliseconds = 5 seconds
            return;
        }
        setReward(false);
        setShowSpinResult(false);
        setShowAlert(false);
        userService.spinTheWheel(ads_count)
            .then((res) => {
                setPrizeNumber(res.selected_index);
                setSpinResult(res.selected_index);
                setReward(res.bonus)
                setMustSpin(true);
            })
            .catch((err) => {
                if (typeof (err) !== 'object') {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('Something went wrong, please try again in a bit.');
                }
                setShowAlert(true);
            })
    };

    return (
        <div className="spin-the-wheel-section">
            <h2 className="section-title">Daily Spin the Wheel!</h2>
            <p>Try Your Luck! Spin the Wheel and Earn Exciting Cash Rewards!</p>

            <div className="wheel-container">

                <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data} // Extract options for the Wheel
                    outerBorderColor={["#f2f2f2"]}
                    outerBorderWidth={[10]}
                    innerBorderColor={["#f2f2f2"]}
                    radiusLineColor={["#dedede"]}
                    radiusLineWidth={[1]}
                    fontSize={12}
                    textColors={["#ffffff"]}
                    backgroundColors={backgroundColors} // Using the colors array
                    onStopSpinning={() => {
                        setMustSpin(false);
                        setShowSpinResult(true)
                        // set timer in case first spin of day, ideally backend should give this time back
                        // Calculate tomorrow's midnight
                        if (!showingAd) {
                        //    let tomorrowMidnight = new Date();
                    //        tomorrowMidnight.setDate(tomorrowMidnight.getDate() + 1);
                //            tomorrowMidnight.setHours(0, 0, 0, 0);
            //                const now = new Date();
          //                  const nowIst = getCurrentDateInIST(now);
                            const timeDiff = 4*60*60*1000;
                            if (timeDiff > 0) {
                                setTimer(Math.floor(timeDiff / 1000)); // Set timer in seconds
                                setTimerActive(true)
                            }
                        }
                        //set ads timer after 10 mins
                        else {
                            userService.updateUserSpinWheelAdsStats()
                            updateAdsDataState();
                            setShowingAd(false);
                            setAdTimerActive(true);
                        }
                    }}
                />
            </div>

            <Button color='info' style={{ paddingLeft: "15px", paddingRight: "15px" }}
                className={`spin-button ${ timerActive ? 'disabled' : ''}`}
                onClick={handleSpinWithoutAd} disabled={timerActive || mustSpin}
            >
                { timerActive
                ? `Spin in ${formatTime(timer)}`
                : "Spin Now"}
            </Button>

            {/*{adCount < 10 && timerActive && <Button*/}
            {/*    color='info'*/}
            {/*    style={{ paddingLeft: '15px', paddingRight: '15px' }}*/}
            {/*    className={`spin-button ${adTimerActive ? 'disabled' : ''}`}*/}
            {/*    onClick={handleSpinWithAdCount}*/}
            {/*    disabled={adTimerActive || mustSpin}*/}
            {/*>*/}
            {/*    {adTimerActive ? `Wait ${formatTime(adTimer)} seconds for new ad` : 'Watch Ad for Extra Spin'}*/}
            {/*</Button> }*/}


            {spinResult >= 0 && showSpinResult && (
                spinResult === 3 || spinResult === 6 ? (
                    <div className="spin-result">
                        <p>Oh, no - <strong>{data[spinResult].option}</strong></p>
                    </div>
                ) : (
                    <div className="spin-result">
                        <p>
                            {data[spinResult].option === "FEELING LUCKY" ? (
                                <>
                                    <>Congratulations,You won ₹<strong>{reward}</strong></> {/* Use dynamic reward */}
                                </>
                            ) : (
                                <>Congratulations, <strong>{data[spinResult].result}</strong></>
                            )}
                        </p>
                    </div>

                )
            )}
            <Alert color="danger" isOpen={showAlert} >
                <Container>
                    <button
                        type="button"
                        className="close"
                        onClick={(e) => { setShowAlert(false); }}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                    {errMsg}<br></br>
                </Container>
            </Alert>

            <Alert color="danger" isOpen={showNoAdAlert} >
                <Container>
                    <button
                        type="button"
                        className="close"
                        onClick={(e) => { setShowNoAdAlert(false); }}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                    Currently, no ads are available. Please try again later.<br></br>
                </Container>
            </Alert>

            {/* Google AdSense or Google Ads Script integration
            <div id="ad-container">
                <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    ref={adUnitRef}
                    data-ad-client="ca-pub-4623951234748268"
                    data-ad-slot="9824488931"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script>
            </div> */}

            <MyModalDialog title="" show={showErrorDialog} onClose={() => { setShowErrorDialog(false); setErrorMsg(null) }}>
                <Row><Col className="ml-auto mr-auto text-center">
                    {errorMsg && errorHref && <p><a href={errorHref}>{errorMsg}</a></p>}
                    {!errorMsg || !errorHref &&
                        <p style={{color:"#B00610", fontWeight:"bolder"}}>{errorMsg}</p>}
                </Col></Row>
            </MyModalDialog>

        </div>
    );
};

export default SpinTheWheel;
