import React, { useState } from "react";
import { Alert, Button, Col, Container, Row, Table } from "reactstrap";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {load} from '@cashfreepayments/cashfree-js';

import { userService } from "services/user.service";
import { calculateTotalMoney } from "utils/helper";
import upi_code from 'assets/img/upi_code.png';
import { format } from "date-fns";
import MyModalDialog from "./MyModalDialog";
import { copyTextToClipboard } from "utils/helper";
import { artistRoleName } from "utils/constants";
import Streaks from "./Streaks";
import {pixelDepositConversionTracking} from "./../conversion-script";
import { useViewPort } from "utils/helper";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { cashfreeMode } from "utils/constants";

const MyAccountBalance = ({streaksRef}) => {
    const navigate = useNavigate();
    const [showWithdrawDialog, setShowWithdrawDialog] = useState(false);
    const [showDepositDialog, setShowDepositDialog] = useState(false);
    const userData = userService.getLocalStorageData();
    const [data, setData] = useState([]);
    const [txdata, setTxData] = useState([]);
    const [alertWithdraw, setAlertWithdraw] = useState(false);
    const [kycComplete, setKycComplete] = useState(true);
    const [withdrawalAmount, setWithdrawalAmount] = useState(null);
    const [depositAmount, setDepositAmount] = useState(null);
    const [alertDeposit, setAlertDeposit] = useState(false);
    const [userUpiId, setUserUpiId] = useState("user_upi_id" in userData ? userData.user_upi_id : null);
    const [userMobileNumber, setUserMobileNumber] = useState("mobile_number" in userData ? userData.mobile_number : null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorType, setErrorType] = useState(0);
    const minWithdrawalAmount = ("role" in userData ? (userData["role"] === artistRoleName ? 10000 : 100 ) : 100);

    let cashfree;
    const initializeCashfreeSDK = async function () {
        cashfree = await load({
            mode: cashfreeMode
        })
    }
    initializeCashfreeSDK();

    React.useEffect(() => {
        const fetchData = async () => {
            userService.getFantasyUserHistory()
                .then((res) => {
                    setData(res);
                })
                .catch((error) => {
                    return;
                })
            fetchMyTx();
        };
        fetchData();
    }, [])

    const fetchMyTx = () => {
        userService.getMyTx()
            .then((res) => {setTxData(res);})
            .catch((err) => {})
    }

    const printTime = (in_time) => {
        try {
            return (format(in_time, 'dd MMM h:mm a'));
        } catch (e) {
            return in_time;
        }
    }

    const handleShowDepositDialog = (e) => {
        e.preventDefault();
        setShowDepositDialog(true);
    }

    const enableWithdrawDialog = (e) => {
        e.preventDefault();
        if (!('winnings' in userData)) {
            return;
        }
        if (userUpiId === null || userUpiId === undefined || userMobileNumber === null || userMobileNumber === undefined ) {
            setKycComplete(false);
            return;
        }
        if (userData['winnings'] < minWithdrawalAmount) {
            setAlertWithdraw(true);
            return;
        }
        setShowWithdrawDialog(true);
    }

    const handleWithdrawalAmountInput = (e) => {
        e.preventDefault();
        const val = parseInt(e.target.value);
        if (val === null || val === undefined || isNaN(val)) {
            setWithdrawalAmount('');
            return;
        }
        if (val > parseInt(userData["winnings"])) {
            return;
        }
        setWithdrawalAmount(val);
    }

    const handleDepositAmountInput = (e) => {
        e.preventDefault();
        const val = parseInt(e.target.value);
        if (val === null || val === undefined || isNaN(val)) {
            setDepositAmount('');
            return;
        }
        if ( val < 100 || val > 50000) {
//            return;
            setAlertDeposit(true);
        } else  {
            setAlertDeposit(false);
        }
        setDepositAmount(val);
    }

    const handleUserUpiIdChanges = (e) => {
        e.preventDefault();
        setUserUpiId(e.target.value);
    }

    const verifyCashfreePayment = async (orderId, totalPrice) => {
        userService.verifyCashfreePayment({"order_id": orderId})
        .then((res) => {
            window.dispatchEvent( new Event('storage') );
            pixelDepositConversionTracking(totalPrice);
            setShowSuccess(true);
            setDepositAmount(null);
            fetchMyTx();
            setTimeout(() => {
                setShowDepositDialog(false);
                setShowSuccess(false);
            }, 5000);
        })
        .catch((err) => {
            alert(err);
        })
    }

    const initiateWithdrawalTx = (e) => {
        e.preventDefault();
        setErrorType(0);
        let val = parseInt(withdrawalAmount);
        if (val === null || val === undefined || isNaN(val) || val < minWithdrawalAmount || val > parseInt(userData["winnings"])) {
            setErrorType(1);
            return;
        }
        if (userUpiId === null || userUpiId === undefined || userUpiId.trim().length === 0) {
            setErrorType(2);
            return;
        }

        userService.withdrawMoney(val, userUpiId)
        .then((res) => {
            setShowSuccess(true);
            setWithdrawalAmount(null);
            fetchMyTx();
            setTimeout(() => {
                setShowWithdrawDialog(false);
                setShowSuccess(false);
            }, 2000);
        })
        .catch((err) => {
            alert(err);
        })
    }

    const initiateDepositTx = (e) => {
        e.preventDefault();
        setErrorType(0);
        let val = parseInt(depositAmount);
        if ((val === null || val === undefined || isNaN(val) || val < 100 || val > 50000 )) {
            setErrorType(1);
            setAlertDeposit(true);
            return;
        }
        userService.depositMoney(val)
        .then((res) => {
            try {
                let sessionId = res["payment_session_id"];
                let cashfree_checkoutOptions = {
                    paymentSessionId: sessionId,
                    redirectTarget: "_modal", // show popup in our website instead of cashfree payment page
                }
                cashfree.checkout(cashfree_checkoutOptions)
                .then((result) => {
                    if (result.error) {
                        // This will be true when there is any error during the payment
                        setShowDepositDialog(false);
                        userService.cancelUserTx(res["order_id"])
                    }
                      if (result.redirect) {
                        // This will be true when the payment redirection page couldnt be opened in the same window
                        // This is an exceptional case only when the page is opened inside an inAppBrowser
                        // In this case the customer will be redirected to return url once payment is completed
                        console.log("Payment will be redirected");
                      }
                      if (result.paymentDetails) {
                        // This will be called whenever the payment is completed irrespective of transaction status
                        console.log("Payment has been completed, Check for Payment Status");
                        console.log(result.paymentDetails);
                        // payment initialized
                        verifyCashfreePayment(res["order_id"], val);    
                        }
                })
                .catch((err) => {
                    alert(err);
                })
            } catch (err) {
                alert(err);
            }    
        })
        .catch((err) => {
            alert(err);
        })
    }

    const handleCoinsClick = (e) => {
        e.preventDefault();
        navigate('/coins');
    }

    return (
        <div>
                <Row>
                    <Col className="ml-auto mr-auto">

                        <h3 style={{ marginBottom: "10px" }}>Total Balance</h3>
                        <CurrencyRupeeIcon />
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{calculateTotalMoney(userData)}</span>

                        <h4 style={{ marginTop: "50px", marginBottom: "10px" }}>Money added (Unutilized)</h4>
                        <CurrencyRupeeIcon />
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{'account_unutilized' in userData ? userData['account_unutilized'] : 0}</span>
                        <Button className='btn-round' color="info"
                                onClick={handleShowDepositDialog}
                                style={{marginLeft: "1.25rem"}}>Deposit Money</Button>
                        <Row className="center">
                            <p className="blink" style={{
                                color:"green",
                                fontWeight:"bold",
                                margin:"auto",
                                textAlign:"center"

                            }}>GET 100% CASHBACK UP TO ₹100 ON YOUR FIRST DEPOSIT!</p>
                        </Row>
                        <h4 style={{marginTop: "50px", marginBottom: "10px"}}>Bonus Money</h4>
                        <CurrencyRupeeIcon/>
                        <span style={{
                            fontSize: "larger",
                            fontWeight: "bold"
                        }}>{'bonus' in userData ? userData['bonus'] : 0}</span>

                        <h4 style={{ marginTop: "50px", marginBottom: "10px" }}>Winnings</h4>
                        <CurrencyRupeeIcon />
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{'winnings' in userData ? userData['winnings'] : 0}</span>
                        <Button className='btn-round' color="info"
                            onClick={enableWithdrawDialog}
                            style={{marginLeft: "1.25rem"}}>Withdraw</Button>
                        <Alert color="danger" isOpen={!kycComplete}>
                            <Container>
                                Complete your KYC first by adding UPI & Mobile Number details in profile section
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setKycComplete(true)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>
                        <Alert color="danger" isOpen={alertWithdraw}>
                            <Container>
                                Minimum ₹{minWithdrawalAmount} is required to make a withdrawal request.
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setAlertWithdraw(false)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>

                        <h4 onClick={(e) => handleCoinsClick(e)} style={{ marginTop: "50px", marginBottom: "10px",
                            textDecoration: "underline"
                         }}>Coins</h4>
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{'coins' in userData ? userData['coins'] : 0}</span>

                        <h3 style={{ marginTop: "50px", marginBottom: "0px" }}>My Games</h3>
                        <Table borderless style={{ display: "block", overflow: "auto" }}>
                            <thead style={{ backgroundColor: "#320544", color: "white" }}>
                                <tr>
                                    <th>
                                        Game
                                    </th>
                                    <th>
                                        Game Price
                                    </th>
                                    <th>
                                        Bonus Used
                                    </th>
                                    <th>
                                        Price Paid
                                    </th>
                                    <th>
                                        Score
                                    </th>
                                    <th>
                                        Game Earnings
                                    </th>
                                    <th>
                                        Withdrawable Winning Amount
                                    </th>
                                    <th>
                                        Payment Time
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((rowData, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">
                                                {rowData.name}
                                                <div className="team-name">
                                                    {"Team "}{(rowData.team_number)}
                                                </div>
                                            </th>
                                            <td>
                                            ₹{rowData.price}
                                            </td>
                                            <td>
                                            ₹{rowData.bonus_used}
                                            </td>
                                            <td>
                                            ₹{rowData.total_price}
                                            </td>
                                            <td>
                                                {parseFloat(rowData.score).toFixed(2)}
                                            </td>
                                            <td>
                                            ₹{rowData.earnings}
                                            </td>
                                            <td>
                                            ₹{rowData.withdrawable_winnings}
                                            </td>
                                            <td>
                                                {printTime(rowData.team_created_at)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>

                        <h3 style={{ marginTop: "50px", marginBottom: "0px" }}>My Transactions</h3>
                        <Table borderless style={{ display: "block", overflow: "auto" }}>
                            <thead style={{ backgroundColor: "#320544", color: "white" }}>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        UPI Id
                                    </th>
                                    <th>
                                        Time
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {txdata.map((rowData, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">
                                                {index+1}
                                            </th>
                                            <th scope="row">
                                            ₹{rowData.amount}
                                            </th>
                                            <td>
                                                {rowData.tx_type}
                                            </td>
                                            <td>
                                                {rowData.user_upi_id}
                                            </td>
                                            <td>
                                                {printTime(rowData.created_at)}
                                            </td>
                                            <td>
                                                {rowData.tx_status}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>

                        {/*<div ref={streaksRef}>*/}
                        {/*    <Streaks/>*/}
                        {/*</div>*/}

                    </Col>
                </Row>

            <MyModalDialog title="Withdraw your winnings" show={showWithdrawDialog} onClose={(e) => setShowWithdrawDialog(false)}>
                    <div className="carousel-item active withdrawal">
                        <div>
                            <Row className="text-center content-center" style={{ margin: "auto", marginTop: "10px" }}>
                                <Col className="ml-auto mr-auto text-center">

                                    <input type="number" placeholder="Withdrawal amount" value={withdrawalAmount} onChange={handleWithdrawalAmountInput}
                                        className={"ml-auto mr-auto text-center field center" + (errorType === 1 ? " error-input": "")} style={{ textAlign: "center" }}></input>
                                    <p>Min: <CurrencyRupeeIcon /><strong>100</strong> - Max: <CurrencyRupeeIcon /><strong>1 lakh</strong> per day</p>

                                    <h4 style={{ marginTop: "50px" }}>Winnings will be sent to</h4>
                                        <input type="text" placeholder="Your UPI Id" value={userUpiId} disabled onChange={handleUserUpiIdChanges}
                                             className={"ml-auto mr-auto text-center field center" + (errorType === 2 ? " error-input": "")} style={{ textAlign: "center" }}></input>

                                    <Col>
                                        <span style={{ textAlign: "center" , fontWeight: "lighter", fontSize: "smaller" }}>Con10Craze is not responsible if you provided a wrong UPI ID.</span>
                                    </Col>
                                    <Button className="btn-round" size="lg" style={{ marginTop: "30px" }}
                                        color="danger"
                                        type="button"
                                        onClick={initiateWithdrawalTx}
                                    >Withdraw Now</Button>
                                    {showSuccess && <Row>
                                        <span style={{ fontWeight: "bold" }}>Congratulations, you should receive a payment from con10craze@okhdfcbank shortly. In case of any issue, tou can contact us via email at contact-us@con10craze.com.</span>
                                    </Row>}
                                </Col>
                            </Row>
                        </div>
                    </div>

                </MyModalDialog>

                <MyModalDialog title="Deposit Money" show={showDepositDialog} onClose={(e) => setShowDepositDialog(false)}>
                    <div>
                        <Row className="text-center content-center" style={{ margin: "auto", marginTop: "10px" }}>
                            <input type="number" placeholder="Deposit amount" value={depositAmount} onChange={handleDepositAmountInput}
                                className={"ml-auto mr-auto text-center field center" + (errorType === 1 ? " error-input": "")} style={{ textAlign: "center" }}></input>
                        </Row>
                        <Alert color="danger" isOpen={alertDeposit}>
                            <Container>
                                Please deposit between ₹100 and ₹50,000 only.
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setAlertDeposit(false)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>

                        <Row className="text-center content-center" style={{ margin: "auto", marginTop: "20px" }}>
                            <Button className="ml-auto mr-auto text-center btn-round" size="lg"
                                color="danger"
                                type="button"
                                onClick={initiateDepositTx}
                            >SUBMIT</Button>
                        </Row>

                        <Col className="ml-auto mr-auto text-center">
                                {showSuccess && <Row>
                                    <span style={{ fontWeight: "bold" }}>Congratulations, your deposit request has been received and your money would be reflected in your Con10Craze account shortly. In case of any issue, you can contact us via email at contact-us@con10craze.com.</span>
                                </Row>}
                        </Col>
                    </div>
                </MyModalDialog>

        </div>
    )
}

export default MyAccountBalance;