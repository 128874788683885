import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane } from 'reactstrap';
import { useTimer } from "react-timer-hook";
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockIcon from '@mui/icons-material/Lock';

import SEO from '../utils/seo';
import not_found from './../assets/img/not-found.jpg';
import { userService } from 'services/user.service';
import MyModalDialog from './index-sections/MyModalDialog';
import CreatePrivateContest from './index-sections/CreatePrivateContest';

const AllFantasyGames = () => {
    const [upcoming, setUpcoming] = React.useState([]);
    const [ongoing, setOngoing] = React.useState([]);
    const [completed, setCompleted] = React.useState([]);
    const [privateContests, setPrivateContests] = React.useState([]);
    const navigate = useNavigate();
    const [iconPills, setIconPills] = React.useState("1");
    const [gamesLoaded, setGamesLoaded] = useState(false);
    const userData = userService.getLocalStorageData();
    const userLevel = ('level' in userData && userData['level'] > 0) ? userData['level'] : 1;
    const [playableMoney, setPlayableMoney] = useState(null);

    const [showCreateContestDialog, setShowCreateContestDialog] = useState(false);

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        let total = 0;
        if ('account_unutilized' in userData && userData['account_unutilized'] > 0) {
            total = total + userData['account_unutilized'];
        }
        if ('bonus' in userData && userData['bonus'] > 0) {
            total = total + userData['bonus'];
        }
        setPlayableMoney(total);
    
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        if (userService.isLoggedIn()) {
            userService.getUserWalletDetails();
        }    

        if (upcoming.length === 0) {
            userService.getFantasyUpcomingGames()
                .then((res) => {
                    setUpcoming(res);
                    setGamesLoaded(true)
                })
                .catch((err) => { })
        }

        if (ongoing.length === 0) {
            userService.getFantasyLiveGames()
                .then((res) => {
                    setOngoing(res);
                })
                .catch((err) => { })
        }

        if (privateContests.length === 0) {
            userService.getFantasyPrivateGames()
                .then((res) => {
                    setPrivateContests(res);
                })
                .catch((err) => { })
        }

        if (completed.length === 0) {
            userService.getFantasyCompletedGames()
                .then((res) => {
                    setCompleted(res);
                })
                .catch((err) => { })
        }
    }, []);

    const handleParticipate = (e, game) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/game/' + game["uuid"]);
    }

    const displayTime = (input) => {
        return (
            formatDistance(input, new Date(), { addSuffix: true })
        );
    }

    const MyTimer = ({ expiryTimestamp }) => {
        const {
            seconds,
            minutes,
            hours,
            days,
            isRunning,
            start,
            pause,
            resume,
            restart
        } = useTimer({
            expiryTimestamp,
            autoStart: true, // Automatically start the timer when component mounts
        });

        // Optionally, you could use setInterval to ensure the timer continues to update at a second interval
        useEffect(() => {
            if (isRunning) {
                const interval = setInterval(() => {
                    // Force re-render every second to show updated time
                }, 1000);

                return () => clearInterval(interval); // Clean up the interval on component unmount or when not running
            }
        }, [isRunning]);

        return (
            <div>
            <span style={{ fontStyle: "italic", fontSize: "small" }}>
                Starts in:&nbsp;
                {days > 0 && `${days}d `}
                {hours > 0 && `${hours}h `}
                {minutes > 0 && `${minutes}m `}
                {seconds}s
            </span>
            </div>
        );
    };

    const privatecontests = () => {
        if (!gamesLoaded) {
            return <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        }

        return (
            <>
                <Row>
                    <Col className='ml-auto mr-auto' style={{ marginBottom: "30px" }}>
                        <span>
                            Set up your own Fantasy Contest to compete with friends or a select group.
                            Customize the game with your rules, prizes, and player limits for a fun, personalized experience.<br></br><br></br>
                        </span>
                        <Button color="success" style={{ borderRadius: "100px" }}
                                onClick={(e) => setShowCreateContestDialog(true)}>
                            <AddCircleOutlineIcon style={{ fontSize: '32px' }} />
                            Create your Private Contest
                        </Button>
                    </Col>
                </Row>
                <Container>
                    <Row>
                        {privateContests.map((game) => {
                            let prizeDistribution = Math.floor(game.max_users * game.price * game.winners_users_percent / 100);
                            const spots = game.max_users > 0 ? game.max_users : 1000;
                            const remSpots = game.interested > 0 ? spots - game.interested : spots;
                            let now_time = new Date();
                            let start_time = new Date(game.start_time);
                            let end_time = new Date(game.end_time);
                            const isUpcoming = start_time > now_time;
                            const isActive = !isUpcoming && end_time > now_time;
                            const status = (isUpcoming ? 'upcoming' : (isActive ? 'live' : 'completed' ));
                
                            if (!isUpcoming) {
                                prizeDistribution = Math.floor(game.interested * game.price * game.winners_users_percent / 100);
                            }

                            let userParticipated = "user_participated" in game && game["user_participated"] === true;
                            return (
                                <Col className="ml-auto mr-auto" xs="12" md="4" style={{ marginBottom: "30px" }}>
                                    <Card className="card-signup"
                                        style={{
                                            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.4)", border: "2px solid #dddddd",
                                            borderRadius: "20px"
                                        }}
                                        onClick={(e) => handleParticipate(e, game)}>
                                        {game.parent_name &&
                                            <h3 style={{
                                                marginTop: "5px",

                                                borderTopLeftRadius: "20px",
                                                borderTopRightRadius: "20px",
                                                margin: "5px",
                                                fontWeight: "bold",
                                                backgroundColor: "#320544", color: "white"
                                            }}>{game.parent_name}</h3>}
                                        <CardBody style={{ padding: "5px" }}>
                                            <Col>
                                                <Row>
                                                    {/*                                                    <Col className="ml-auto mr-auto" xs="6">
                                                        <img style={{marginTop: "10px"}}
                                                                alt="Con10Craze Fantasy Creators, Artists, Influencers, Celebrities"
                                                                className="rounded img-raised"
                                                                src={game.image}
                                                        ></img>
                                                    </Col>*/}

                                                    <Col className="ml-auto mr-auto">
                                                        <h5 style={{
                                                            fontWeight: "bold",
                                                            marginTop: "10px",
                                                            marginBottom: "10px",
                                                        }}>{game.name}</h5>
                                                        {"me_owner" in game && game["me_owner"] === true && 
                                                        <p style={{ fontStyle: "italic", fontSize: "14px", marginBottom: "0.7rem" }}>(Created by me)</p>}
                                                        {status === "upcoming" &&
                                                            <MyTimer
                                                                expiryTimestamp={new Date(game.start_time)}></MyTimer>}
                                                        {status === "ongoing" &&
                                                            <div>Ends {displayTime(game.end_time)}</div>}
                                                        {status === "completed" &&
                                                            <div>Ended {displayTime(game.end_time)}</div>}
                                                        <i className="now-ui-icons sport_trophy pull-left" style={{
                                                            fontWeight: "bolder",
                                                            marginTop: "20px",
                                                            marginRight: "0.5rem",
                                                            color: "#FFD700"
                                                        }}></i>
                                                        {status === "upcoming" &&
                                                            <Row>
                                                                <span className='pull-left' style={{ color: "#B00610", fontWeight: "bold", marginTop: "15px" }}>*₹{prizeDistribution.toLocaleString()}</span>
                                                            </Row>

                                                        }
                                                        {status !== "upcoming" && <span className='pull-left' style={{ color: "#B00610", fontWeight: "bold", marginTop: "15px" }}>₹{prizeDistribution.toLocaleString()}</span>}
                                                    </Col>
                                                </Row>

                                                {/*<Progress*/}
                                                {/*    max={spots}*/}
                                                {/*    value={spots - remSpots}*/}
                                                {/*    style={{ marginTop: "10px" }}*/}
                                                {/*/>*/}
                                                <div className="progress">
                                                    <div className="progress-bar-animated progress-bar progress-bar-striped  bg-success" role="progressbar"
                                                        aria-valuenow={spots - remSpots} // Use the variable here
                                                        aria-valuemin="0"
                                                        aria-valuemax={spots}
                                                        style={{
                                                            animation: "progress-bar-stripes 0.3s linear infinite", width: `${(spots - remSpots) / spots * 100}%`
                                                        }}></div>
                                                </div>
                                                <Row>

                                                    <Col className="ml-auto mr-auto">
                                                        <div className="pull-left">
                                                            {remSpots} spots left
                                                        </div>
                                                        <div className="pull-right">
                                                            {spots} spots
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <hr style={{ height: "5px" }} />

                                                {/*                                                {status === "upcoming" && !userParticipated &&
>>>>>>> 0b9f2ad (Adding new UI componetns commented out for now)
                                                <Row style={{fontWeight: "500"}}>
                                                <Col className="ml-auto mr-auto" xs="6">
                                                    <i className="now-ui-icons users_single-02"
                                                        style={{fontWeight: "bold", marginRight: "0.25rem"}}></i>
                                                    {game.interested} Players
                                                </Col>
                                                <Col className="ml-auto mr-auto" xs="6">
                                                    {game.price <= playableMoney && <span>FREE<br></br></span>}

                                                    {game.price <= playableMoney && <span style={{ textDecoration: "line-through" }}>Fee: ₹{game.price}</span>}
                                                    {game.price > playableMoney && <span>Fee: ₹{game.price}</span>}
                                                </Col>
                                            </Row>}

                                            {status !== "upcoming" || userParticipated && */}
                                                <Row style={{ fontWeight: "500" }}>
                                                    {/*                                                    <Col className="ml-auto mr-auto" xs="6">
                                                        <i className="now-ui-icons users_single-02"
                                                            style={{fontWeight: "bold", marginRight: "0.25rem"}}></i>
                                                        {game.interested} Players
                                                    </Col>*/}
                                                    {status === "completed" &&
                                                        <Col className="ml-auto mr-auto" style={{
                                                            color: "green", fontWeight: "bold",
                                                            display: "flex", justifyContent: "center", alignItems: "center"
                                                        }}>
                                                            <span>Fee: ₹{game.price}</span>
                                                        </Col>
                                                    }

                                                    {status !== "completed" &&
                                                        <Col className="ml-auto mr-auto" xs="4" style={{
                                                            color: "green", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"
                                                        }}>
                                                            <span>Fee: ₹{game.price}</span>
                                                        </Col>}

                                                    {status !== "completed" &&
                                                        <Col className="ml-auto mr-auto" xs="8">
                                                            {status === "upcoming" && userParticipated &&
                                                                <Button color="info" style={{ width: "100%", borderRadius: "100px" }}
                                                                    onClick={(e) => handleParticipate(e, game)}>MONITOR YOUR
                                                                    TEAM
                                                                </Button>
                                                            }
                                                            {status === "upcoming" && !userParticipated &&
                                                                <Button color="info" style={{ width: "100%", borderRadius: "100px" }}
                                                                    onClick={(e) => handleParticipate(e, game)}>PLAY NOW
                                                                </Button>
                                                            }

                                                            {status === "ongoing" &&
                                                                <Button color="info" style={{ width: "100%", borderRadius: "100px" }}
                                                                    onClick={(e) => handleParticipate(e, game)}>Watch Live Score
                                                                </Button>
                                                            }
                                                        </Col>}

                                                </Row>

                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </>        
        )
    }

    const gamesLayout = (games, status) => {
        let icon = "1";
        let message = "";
        if (status === "upcoming") {
            message = "Watch Live Games"
            icon = "2"
        } else if (status === "ongoing") {
            message = "Check Upcoming Games"
            icon = "1"
        } else {
            message = "Check Upcoming Games"
            icon = "1"
        }
        if (!gamesLoaded) {
            return <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        }

        if (gamesLoaded && (!games || games.length === 0)) {
            return (
                <div className="no-games">
                    <img src={not_found} alt="No games" className="no-games-image" />
                    <Button color="info" style={{ borderRadius: "40px" }}
                        onClick={(e) => {
                            handleTabChange(e, icon)
                        }}>
                        {message}
                    </Button>
                    {/*<h4 className="no-games-message" style={{ color:"red",fontWeight:"bold"}}>{message}</h4>*/}
                </div>
            )
        }

        if (gamesLoaded) {
            return (
                <Container>
                    {status === "upcoming" && <Row>
                        <Col className="ml-auto mr-auto"  style={{ marginBottom: "30px" }}>
                            <Button color="success" style={{ borderRadius: "100px" }}
                                onClick={(e) => setShowCreateContestDialog(true)}>
                                <AddCircleOutlineIcon style={{ fontSize: '32px' }} />
                                Create your Private Contest
                            </Button>

                        </Col>
                    </Row>}
                    <Row>
                        {games.map((game) => {
                            let prizeDistribution = Math.floor(game.max_users * game.price * game.winners_users_percent / 100);
                            const spots = game.max_users > 0 ? game.max_users : 1000;
                            const remSpots = game.interested > 0 ? spots - game.interested : spots;
                            const gameLocked = game.min_level && game.min_level > userLevel;
                            if (status !== "upcoming") {
                                prizeDistribution = Math.floor(game.interested * game.price * game.winners_users_percent / 100);
                            }

                            let userParticipated = "user_participated" in game && game["user_participated"] === true;
                            return (
                                <Col className="ml-auto mr-auto" xs="12" md="4" style={{ marginBottom: "30px" }}>
                                    <Card className="card-signup"
                                        style={{
                                            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.4)", border: "2px solid #dddddd",
                                            borderRadius: "20px"
                                        }}
                                        onClick={(e) => handleParticipate(e, game)}>
                                        {game.parent_name &&
                                            <h3 style={{
                                                marginTop: "5px",

                                                borderTopLeftRadius: "20px",
                                                borderTopRightRadius: "20px",
                                                margin: "5px",
                                                fontWeight: "bold",
                                                backgroundColor: "#320544", color: "white"
                                            }}>{game.parent_name}</h3>}
                                        <CardBody style={{ padding: "5px" }}>
                                            <Col>
                                                <Row>
                                                    {/*                                                    <Col className="ml-auto mr-auto" xs="6">
                                                        <img style={{marginTop: "10px"}}
                                                             alt="Con10Craze Fantasy Creators, Artists, Influencers, Celebrities"
                                                             className="rounded img-raised"
                                                             src={game.image}
                                                        ></img>
                                                    </Col>*/}

                                                    <Col className="ml-auto mr-auto">
                                                        <h5 style={{
                                                            fontWeight: "bold",
                                                            marginTop: "10px",
                                                            marginBottom: "10px",
                                                        }}>{game.name}</h5>
                                                        {status === "upcoming" &&
                                                            <MyTimer
                                                                expiryTimestamp={new Date(game.start_time)}></MyTimer>}
                                                        {status === "ongoing" &&
                                                            <div>Ends {displayTime(game.end_time)}</div>}
                                                        {status === "completed" &&
                                                            <div>Ended {displayTime(game.end_time)}</div>}
                                                        <i className="now-ui-icons sport_trophy pull-left" style={{
                                                            fontWeight: "bolder",
                                                            marginTop: "20px",
                                                            marginRight: "0.5rem",
                                                            color: "#FFD700"
                                                        }}></i>
                                                        {status === "upcoming" &&
                                                            <Row>
                                                                <span className='pull-left' style={{ color: "#B00610", fontWeight: "bold", marginTop: "15px" }}>*₹{prizeDistribution.toLocaleString()}</span>
                                                            </Row>

                                                        }
                                                        {status !== "upcoming" && <span className='pull-left' style={{ color: "#B00610", fontWeight: "bold", marginTop: "15px" }}>₹{prizeDistribution.toLocaleString()}</span>}
                                                    </Col>
                                                </Row>

                                                {/*<Progress*/}
                                                {/*    max={spots}*/}
                                                {/*    value={spots - remSpots}*/}
                                                {/*    style={{ marginTop: "10px" }}*/}
                                                {/*/>*/}
                                                <div className="progress">
                                                    <div className="progress-bar-animated progress-bar progress-bar-striped  bg-success" role="progressbar"
                                                        aria-valuenow={spots - remSpots} // Use the variable here
                                                        aria-valuemin="0"
                                                        aria-valuemax={spots}
                                                        style={{
                                                            animation: "progress-bar-stripes 0.3s linear infinite", width: `${(spots - remSpots) / spots * 100}%`
                                                        }}></div>
                                                </div>
                                                <Row>

                                                    <Col className="ml-auto mr-auto">
                                                        <div className="pull-left">
                                                            {remSpots} spots left
                                                        </div>
                                                        <div className="pull-right">
                                                            {spots} spots
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <hr style={{ height: "5px" }} />

                                                {/*                                                {status === "upcoming" && !userParticipated &&
>>>>>>> 0b9f2ad (Adding new UI componetns commented out for now)
                                                <Row style={{fontWeight: "500"}}>
                                                <Col className="ml-auto mr-auto" xs="6">
                                                    <i className="now-ui-icons users_single-02"
                                                       style={{fontWeight: "bold", marginRight: "0.25rem"}}></i>
                                                    {game.interested} Players
                                                </Col>
                                                <Col className="ml-auto mr-auto" xs="6">
                                                    {game.price <= playableMoney && <span>FREE<br></br></span>}

                                                    {game.price <= playableMoney && <span style={{ textDecoration: "line-through" }}>Fee: ₹{game.price}</span>}
                                                    {game.price > playableMoney && <span>Fee: ₹{game.price}</span>}
                                                </Col>
                                            </Row>}

                                            {status !== "upcoming" || userParticipated && */}
                                                <Row style={{ fontWeight: "500" }}>
                                                    {/*                                                    <Col className="ml-auto mr-auto" xs="6">
                                                        <i className="now-ui-icons users_single-02"
                                                           style={{fontWeight: "bold", marginRight: "0.25rem"}}></i>
                                                        {game.interested} Players
                                                    </Col>*/}
                                                    {status === "completed" &&
                                                        <Col className="ml-auto mr-auto" style={{
                                                            color: "green", fontWeight: "bold",
                                                            display: "flex", justifyContent: "center", alignItems: "center"
                                                        }}>
                                                            <span>Fee: ₹{game.price}</span>
                                                        </Col>
                                                    }

                                                    {status !== "completed" &&
                                                        <Col className="ml-auto mr-auto" xs="4" style={{
                                                            color: "green", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"
                                                        }}>
                                                            <span>Fee: ₹{game.price}</span>
                                                        </Col>}

                                                    {status !== "completed" &&
                                                        <Col className="ml-auto mr-auto" xs="8">
                                                            {status === "upcoming" && userParticipated &&
                                                                <Button color="info" style={{ width: "100%", borderRadius: "100px" }}
                                                                    onClick={(e) => handleParticipate(e, game)}>MONITOR YOUR
                                                                    TEAM
                                                                </Button>
                                                            }
                                                            {status === "upcoming" && !userParticipated && 
                                                            (!gameLocked ?
                                                                (<Button color="info" style={{ width: "100%", borderRadius: "100px" }}
                                                                    onClick={(e) => handleParticipate(e, game)}>PLAY NOW
                                                                </Button>)
                                                                :
                                                                (<Button color="info" disabled style={{ width: "100%", borderRadius: "100px",
                                                                    display: "flex"
                                                                 }}>
                                                                    <LockIcon style={{ fontSize: '24px', marginRight: "0.3rem" }} />
                                                                    <span>Unlock at Level {game.min_level}</span>
                                                                </Button>)
                                                            )
                                                            }

                                                            {status === "ongoing" &&
                                                                <Button color="info" style={{ width: "100%", borderRadius: "100px" }}
                                                                    onClick={(e) => handleParticipate(e, game)}>Watch Live Score
                                                                </Button>
                                                            }
                                                        </Col>}

                                                </Row>

                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            )
        }
    }

    const handleTabChange = (e, tabIndex) => {
        e.preventDefault();
        setIconPills(tabIndex);
    };

    const handleCreateYourPrivateContest = (e) => {
        e.preventDefault();
    }

    return (
        <div className="section section-tabs" id="tabs-elements">
            <SEO
                title="Play Con10Craze Fantasy Sports Games: Create Dream Teams, Engage with Artists, Earn Real Money!"
                description="Join Con10Craze's Fantasy Sports Games and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores, while artists enjoy revenue from your active participation. Let the games begin!"
                type="website"
                url="https://www.con10craze.com/fantasy-games" />


            <Container>
                <Row>
                    <Col className="ml-auto mr-auto">
                        <Nav className="nav-tabs-neutral justify-content-center"
                            data-background-color="blue" role="tablist" tabs>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "1" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "1")}
                                >
                                    <i className="now-ui-icons media-1_button-play"></i>
                                    Play Now
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "2" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "2")}
                                >
                                    <i className="now-ui-icons tech_tv"></i>
                                    Watch Live Games
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "3" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "3")}
                                >
                                    <i className="now-ui-icons media-1_button-play"></i>
                                    Private Contests
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "4" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "4")}
                                >
                                    <i className="now-ui-icons media-1_button-power"></i>
                                    Completed Games
                                </NavLink>
                            </NavItem>
                        </Nav>


                        <CardBody>
                            <TabContent
                                className="text-center"
                                activeTab={"iconPills" + iconPills}
                            >
                                <TabPane tabId="iconPills1">
                                    {gamesLayout(upcoming, "upcoming")}
                                </TabPane>
                                <TabPane tabId="iconPills2">
                                    {gamesLayout(ongoing, "ongoing")}
                                </TabPane>
                                <TabPane tabId="iconPills3">
                                    {privatecontests()}
                                </TabPane>
                                <TabPane tabId="iconPills4">
                                    {gamesLayout(completed, "completed")}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Col>
                </Row>
            </Container>

            <MyModalDialog title="Create Your Contest" show={showCreateContestDialog} onClose={() => setShowCreateContestDialog(false)}>
                <CreatePrivateContest />
            </MyModalDialog>

            <a
                href="https://chat.whatsapp.com/H4XocOZ6kPHJdSqbMQ7Nwr"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </div>
    );
};

export default AllFantasyGames;
