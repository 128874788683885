import React, { useEffect, useState } from 'react';
import { userService } from 'services/user.service';
import { getCurrentDateInIST } from 'utils/helper';
import MyModalDialog from './MyModalDialog';
import { useNavigate } from 'react-router-dom';
import SpinTheWheel from './SpinTheWheel';

const SpinWheelDialog = ({ showSpinWheelDialog, setShowSpinWheelDialog }) => {
    const [showTrack, setShowTrack] = useState(false);
    const [spinConfetti, setSpinConfetti] = useState(false);
    const navigate = useNavigate();

    const todayDate = getCurrentDateInIST();
    todayDate.setHours(0,0,0,0);

    const completeStreak = () => {
        userService.updateTodayStreakRewards()
        .then((res) => {
            userService.updateLastStreakTime(todayDate);
            // notify user of their new reward
            setShowTrack(true);
            setSpinConfetti(true);
        })
        .catch((err) => { })
    };

    return (
        <div style={{boxShadow:"0 4px 6px -4px rgba(0, 0, 0, 0.1)",borderBottom:"2px solid #dddddd"}} className={`streak-dialog ${showSpinWheelDialog ? 'open' : ''}`}>
            <MyModalDialog backgroundClass = "spin-the-wheel-section-background" showConfetti={spinConfetti} show={showSpinWheelDialog} onClose={(e) => setShowSpinWheelDialog(false)}>
                <SpinTheWheel />
            </MyModalDialog>
        </div>
    );
};

export default SpinWheelDialog;