import React, {useEffect, useState} from 'react';
import {Button, CardBody, Col, Container, Row} from 'reactstrap';

import {useNavigate, useParams} from 'react-router-dom';
import SEO from '../utils/seo';
import games from "../assets/img/game.webp";
import hourGlass from "../assets/img/GAME.mp4";
import {useTimer} from "react-timer-hook";
import {userService} from "../services/user.service";

const PlaygroundNew = () => {
    const {game_external_id} = useParams();
    const navigate = useNavigate();
    const [isUpcoming, setIsUpcmoning] = useState(false)
    const [gameContent, setGameContent] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    React.useEffect(() => {
        userService.getGameCreatorsEngagement(game_external_id)
            .then((res) => {
                setIsLoading(false)
                if (res.posts) {
                    res.posts.forEach((post) => {
                        const {comments = 0, likes = 0, creators = {}} = post;
                        const {followers = 1} = creators; // Default to 1 to avoid division by zero
                        post.score = ((likes + (2 * comments)) / followers) * 100;
                    });

                    res.posts.sort((a, b) => (b.score || 0) - (a.score || 0));
                }
                setGameContent(res); // Update the game content
                const currentTime = new Date();
                const startTime = new Date(res.start_time);
                if (startTime > currentTime) {
                    setIsUpcmoning(true);
                }
            });
    }, []);

    // const game_engagement = {
    //         start_time: "2025-01-05T14:30:00Z",
    //         end_time: "2025-01-07T14:30:00Z",
    //         name:"hello I am the game",
    //         posts: [
    //             {
    //                 creators: {
    //                     social_media_handle : "nikhilsuri507",
    //                     name: "John Doe",
    //                     profile_image: "https://d78eqrbdvopvl.cloudfront.net/1.png",
    //                     followers: 5000,
    //                     select_by: 1
    //                 },
    //                 post_url: "https://www.example.com/post/1",
    //                 likes: 1200,
    //                 comments: 45
    //             },
    //             {
    //                 creators: {
    //                     social_media_handle : "nikhilsuri507",
    //                     name: "Jane Smith",
    //                     profile_image: "https://d78eqrbdvopvl.cloudfront.net/1.png",
    //                     followers: 7000,
    //                     select_by: 1
    //                 },
    //                 post_url: "https://www.example.com/post/2",
    //                 likes: 2300,
    //                 comments: 98
    //             }
    //         ]
    //     };

    const imageStyles = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    };

    const MyTimer = ({prefix, expiryTimestamp }) => {
        const {
            seconds,
            minutes,
            hours,
            days,
            isRunning,
            start,
            pause,
            resume,
            restart
        } = useTimer({
            expiryTimestamp,
            autoStart: true, // Automatically start the timer when component mounts
        });

        // Optionally, you could use setInterval to ensure the timer continues to update at a second interval
        useEffect(() => {
            if (isRunning) {
                const interval = setInterval(() => {
                    // Force re-render every second to show updated time
                }, 1000);

                return () => clearInterval(interval); // Clean up the interval on component unmount or when not running
            }
        }, [isRunning]);

        return (
            <div>
            <span style={{ fontStyle: "italic", fontSize: "small", fontWeight:"bold" }}>
                {prefix} in:&nbsp;
                {days > 0 && `${days}d `}
                {hours > 0 && `${hours}h `}
                {minutes > 0 && `${minutes}m `}
                {seconds}s
            </span>
            </div>
        );
    };
    const InfoBanners = () => {
        return (
            <div style={{ margin: "20px 0" }}>
                <div className="counters-container" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                    {/* Score Calculation Banner */}
                    <div
                        style={{
                            backgroundColor: "#e3f2fd",
                            color: "#0d47a1",
                            borderRadius: "10px",
                            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.4)",
                            padding: "20px",
                            textAlign: "center",
                            flex: "1",
                            maxWidth: "300px",
                        }}
                    >
                        <h5 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>Score Calculation</h5>
                        <p style={{ margin: 0, fontSize: "14px", fontWeight: "500" }}>
                            [( Likes + (2 × Comments)) ÷ Followers ] × 100
                        </p>
                    </div>

                    {/* Sel By Banner */}
                    <div
                        style={{
                            backgroundColor: "#fce4ec",
                            color: "#880e4f",
                            borderRadius: "10px",
                            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.4)",
                            padding: "20px",
                            textAlign: "center",
                            flex: "1",
                            maxWidth: "300px",
                        }}
                    >
                        <h5 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>Selected By</h5>
                        <p style={{ margin: 0, fontSize: "14px", fontWeight: "500" }}>
                            Percentage of users that have selected this creator
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const handleParticipate = (e, game_external_id) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/game/' + game_external_id);
    }


    const artistClicked = (e, artistSocialMediaHandle) => {
        e.preventDefault();
        navigate('/creator/' + artistSocialMediaHandle);
    }

    const section1 = () => {
        const handleBack = () => {
            window.history.back(); // or your navigation logic
        };

        return (
            <div style={{ }}>
                {/* Back Button */}
                <button className="back-button-funky" onClick={handleBack}
                        style={{ position: 'absolute', top: '20px', left: '10px', zIndex: 10 }}>
                    &larr; Back
                </button>

                <Row
                    style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${games})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        color: 'white',
                        padding: '20px',
                        position: 'relative',
                        height: "150px",
                        display: 'flex', // Enable flexbox
                        flexDirection: 'column', // Stack items vertically
                        justifyContent: 'center', // Center items vertically
                        alignItems: 'center', // Center items horizontally
                    }}
                >
                    <div style={{textAlign: "center", marginTop:"30px"}}>
                        {/* User Name */}
                        <h2 style={{fontWeight: 'bold', color: '#FFFFFF'}}>
                            {gameContent.name}
                        </h2>
                        {!isUpcoming && gameContent.end_time && new Date(gameContent.end_time) > new Date() && (       <MyTimer
                            prefix="Ends " expiryTimestamp={new Date(gameContent.end_time)}></MyTimer>)}
                        {!isUpcoming && gameContent.end_time && new Date(gameContent.end_time) < new Date() && (
                            <span style={{fontWeight:"bold"}}>{`${gameContent.start_time} TO ${gameContent.end_time}`}</span>)}
                        {isUpcoming && gameContent.start_time && new Date(gameContent.start_time) > new Date() && (       <MyTimer
                            prefix="Start " expiryTimestamp={new Date(gameContent.start_time)}></MyTimer>)}
                    </div>
                </Row>
            </div>
        );
    };

    const formatNumber = (num) => {
        if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
        if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
        return num;
    };
    return (
        <div>
            <SEO
                title="Watch Live Content and Insights of Game Creators!"
                description="Watch live action of Con10Craze's Fantasy Sports Games and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores. Let the games begin!"
                type="website"
                url={"https://con10craze.com/playground/" + game_external_id} />

            {!isLoading&&<><Col className="ml-auto mr-auto">
            {section1()}
            {isUpcoming && (
                <div className="no-games" style={{marginTop:"60%"}}>
                    <video
                        src={hourGlass}
                        autoPlay
                        loop
                        muted
                        className="no-games-video"
                        style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                    />
                    <p className="title">Game is not live right now</p>
                    <Button
                        color="info"
                        style={{ borderRadius: "40px" }}
                        onClick={(e) => {
                            handleParticipate(e, game_external_id)
                        }}
                    >
                        Create your team here
                    </Button>
                </div>
            )}

            {!isUpcoming && ( <Col className="ml-auto mr-auto">
                <InfoBanners/>
                <div style={{overflow: "scroll",  scrollbarColor: "transparent"}}>

                    <table className="table" style={{padding: "5px"}}>
                        <thead>
                        <tr style={{color: "#000000"}}>
                            <th scope="col" style={{padding: "7px"}}>Creator</th>
                            <th scope="col" style={{padding: "7px"}}>Score</th>
                            <th scope="col" style={{padding: "7px"}}>Likes</th>
                            <th scope="col" style={{padding: "7px"}}>Comments</th>
                            <th scope="col" style={{padding: "7px"}}>Post</th>

                        </tr>
                        </thead>
                        <tbody>
                        {gameContent.posts.map((content, index) => {

                            return <tr key={index}>
                                <td scope="row">
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                        <img
                                            alt="Con10Craze Fantasy Creators"
                                            style={imageStyles}
                                            src={content["creators"]["profile_image"]}
                                            onClick={(e) => artistClicked(e, content["creators"]["social_media_handle"])}
                                        />
                                        <p
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                                textDecoration: "underline",
                                                display: "flex",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                                                backgroundClip: "text",
                                                color: "transparent",
                                                marginTop: "5px", // Space between image and text
                                            }}
                                            onClick={(e) => artistClicked(e, content["creators"]["social_media_handle"])}
                                        >
                                            {content["creators"]["name"]}

                                        </p>
                                        <div style={{marginTop:"-10px", color: "grey",textAlign:"center"}}>{formatNumber(content["creators"]["followers"])}</div>
                                    </div>
                                </td>

                                <td>
                                    <div style={{color: "grey",textAlign:"center"}}>{(content["score"] || 0).toFixed(2)}</div>

                                </td>
                                <td>
                                    <div style={{color: "grey",textAlign:"center"}}>{formatNumber(content["likes"])}</div>
                                </td>
                                <td>
                                    <div style={{color: "grey", textAlign:"center"}}>{formatNumber(content["comments"])}</div>
                                </td>
                                <td>
                                    <div style={{color: "grey",textAlign:"center"}}>
                                        <a href={content["post_url"]} target="_blank" rel="noopener noreferrer"
                                           style={{textDecoration: "underline", color: "blue"}}>
                                            View Post
                                        </a>
                                    </div>
                                </td>

                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </Col>)}
            </Col>
            </>}
        </div>

    )
}

export default PlaygroundNew;
