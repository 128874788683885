import React, { useEffect, useState } from 'react';
import { userService } from 'services/user.service';
import {getCurrentDateInIST, showWhatsappDialog} from 'utils/helper';
import MyModalDialog from './MyModalDialog';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const WhatsappCommunityDialogue = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const todayDate = getCurrentDateInIST();
    todayDate.setHours(0,0,0,0);

    // Check if the streak needs to be completed today
    const showStreakDialog = showWhatsappDialog();

    useEffect(() => {
        setIsDialogOpen(showStreakDialog);
    }, []);

    const handleCross = () => {
        setIsDialogOpen(false);
        userService.updateWhatsappCross(todayDate);
    }

    return (
        <div style={{boxShadow:"0 4px 6px -4px rgba(0, 0, 0, 0.1)",borderBottom:"2px solid #dddddd"}} className={`streak-dialog ${isDialogOpen ? 'open' : ''}`}>
            <MyModalDialog
                // title="Join Our WhatsApp Community"
                show={isDialogOpen}
                onClose={handleCross}
                backgroundClass="whatsapp-modal-bg"
            >
                <div className="whatsapp-community-content" style={{ textAlign: 'center' }}>
                    <WhatsAppIcon style={{ fontSize: '50px', color: 'green' }} />
                    <p style={{ fontWeight: 'bold', margin: '10px 0' }}>Get Game Updates & Win!</p>
                    <p style={{ fontSize: '14px', marginBottom: '20px' }}>
                        🎉 Join our WhatsApp group for exclusive game updates, contests, and cash rewards! Don’t miss out on special promotions—win big now! 💰🔥
                    </p>
                    <button
                        onClick={() => window.open("https://chat.whatsapp.com/H4XocOZ6kPHJdSqbMQ7Nwr", "_blank")}
                        style={{
                            backgroundColor: 'green',
                            color: 'white',
                            border: 'none',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        <WhatsAppIcon style={{ fontSize: '20px', marginRight: '8px' }} />
                        Join Now
                    </button>
                </div>
            </MyModalDialog>
        </div>
    );
};

export default WhatsappCommunityDialogue;