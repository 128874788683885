import React, { useState } from "react";

const PaymentCancellationForm = ({ onClose, onSubmit }) => {
    const [selectedOption, setSelectedOption] = useState("");
    const [additionalText, setAdditionalText] = useState("");
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = () => {

        if (!selectedOption && !additionalText.trim()) {
            setError("Please select an option or provide additional feedback.");
            return;
        }
        const trimmedText = additionalText.trim();
        setError("");
        setIsSubmitting(true);
        const feedback = trimmedText || selectedOption;
        console.log("Form Data Submitted: ", feedback);
        if (onSubmit) {
            onSubmit(feedback); // Pass feedback data to the parent
        }
        // Simulate a brief delay to show the "Thanks for Feedback" message
        setTimeout(() => {
            onClose(); // Close the popup
        }, 2000); // Show the message for 1 second
    };

    return (
        <>
            <form>
                <div style={{ marginBottom: "15px" }}>
                    <label>
                        <input
                            type="radio"
                            value="Payment method not working"
                            checked={selectedOption === "Payment method not working"}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedOption(value);
                                if (value !== "Other reasons") {
                                    setAdditionalText(""); // Clear additionalText when "Other reasons" is deselected
                                }
                            }}
                        />
                        <span style={{ marginLeft: "10px" }}>Payment method not working</span>
                    </label>
                </div>
                <div style={{ marginBottom: "15px" }}>
                    <label>
                        <input
                            type="radio"
                            value="Found better options"
                            checked={selectedOption === "Found better options"}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedOption(value);
                                if (value !== "Other reasons") {
                                    setAdditionalText(""); // Clear additionalText when "Other reasons" is deselected
                                }
                            }}
                        />
                        <span style={{ marginLeft: "10px" }}>Found better options</span>
                    </label>
                </div>
                <div style={{ marginBottom: "15px" }}>
                    <label>
                        <input
                            type="radio"
                            value="Too expensive"
                            checked={selectedOption === "Too expensive"}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedOption(value);
                                if (value !== "Other reasons") {
                                    setAdditionalText(""); // Clear additionalText when "Other reasons" is deselected
                                }
                            }}
                        />
                        <span style={{ marginLeft: "10px" }}>Too expensive</span>
                    </label>
                </div>
                <div style={{ marginBottom: "15px" }}>
                    <label>
                        <input
                            type="radio"
                            value="Other reasons"
                            checked={selectedOption === "Other reasons"}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedOption(value);
                                if (value !== "Other reasons") {
                                    setAdditionalText(""); // Clear additionalText when "Other reasons" is deselected
                                }
                            }}
                        />
                        <span style={{ marginLeft: "10px" }}>Other reasons</span>
                    </label>
                </div>
                {selectedOption === "Other reasons" && (
                    <textarea
                        style={{
                            width: "100%",
                            height: "80px",
                            marginBottom: "15px",
                            padding: "10px",
                            borderRadius: "6px",
                            border: "1px solid #ccc",
                            backgroundColor: "#f8f9fa",
                            resize: "none",
                        }}
                        placeholder="Enter additional feedback (optional, max 200 characters)"
                        maxLength={200}
                        value={additionalText}
                        onChange={(e) => setAdditionalText(e.target.value)}
                    />
                )}
                {error && (
                    <p style={{ color: "red", fontSize: "0.9em", marginBottom: "15px" }}>
                        {error}
                    </p>
                )}
                {!isSubmitting ? (
                    <button
                        type="button"
                        style={{
                            width: "100%",
                            padding: "12px",
                            backgroundColor: "blue",
                            color: "white",
                            border: "none",
                            borderRadius: "6px",
                            cursor: "pointer",
                            fontWeight: "bold",
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                ) : (
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "#B00610",
                            marginTop: "10px",
                        }}
                    >
                        Thanks for your feedback!
                    </p>
                )}
            </form>
        </>
    );
};

export default PaymentCancellationForm;
