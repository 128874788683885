import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import parse from 'html-react-parser';

import { userService } from "services/user.service";
import SEO from "utils/seo";

import logo from "../assets/img/logo.svg";

function Blogs() {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();
    const startDate = new Date(Date.parse("1 Feb 2024"));
    const [blogDatas, setBlogDatas] = useState([]);

    useEffect(() => {
        userService.getBlogs()
            .then((res) => {
                setBlogs(res);
                const itemDataPromises = res.map((blog, index) => {
                    return userService.getBlogData(blog.public_name)
                    .then((blog_res) => {
                        let dt = {'title': blog_res['title'] + " | Con10Craze Fantasy Sports", 'published_at': blog_res['published_at'],
                            'content': blog_res['content']
                        };
                        return dt;
                    })
                    .catch((error) => {
                        return null;
                    })
                });

                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (err) {}
                Promise.all(itemDataPromises)
                .then((allBlogData) => {
                    const validBlogData = allBlogData.filter((data) => data !== null);
                    setBlogDatas(validBlogData);
                })
            })
            .catch((error) => {
            })
    }, [])

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="Latest Insights & Updates | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
                description="Stay up-to-date with the latest news, trends, and insights from the Entertainment industry. Explore our informative blogs covering Content Creators, Influencers, Artists, Fantasy Sports."
                type="blog"
                url="https://www.con10craze.com/blogs" />

            <Container>
                <Row>
                    {blogDatas.map((blog, index) => {
                        let blogDate = new Date(blog["published_at"]);
                        if (blogDate >= startDate) {
                            return (
                                <Row style={{ backgroundColor: index%2 ? '#f0f0f0' : '#fff', marginBottom: "50px" }}>
                                    <Col className="ml-auto mr-auto text-center" md="6">

                                        {blog['title'] && <h3>{blog['title']}</h3>}

                                        {blog['title'] && <p>{format(new Date(blog['published_at']), 'dd MMM, yyyy')}</p>}
                                        {blog['title'] && <div>{parse(blog['content'])}</div>}
                                    </Col>

                                    {index === 1 &&
                                        <div id="ad-container">
                                            <ins className="adsbygoogle"
                                                style={{ display: "block" }}
                                                data-ad-client="ca-pub-4623951234748268"
                                                data-ad-slot="7994007293"
                                                data-ad-format="auto"
                                                data-full-width-responsive="true"></ins>
                                            <script>
                                                (adsbygoogle = window.adsbygoogle || []).push({});
                                            </script>
                                        </div>
                                    }
                                </Row>
                            )
                        }
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default Blogs;
