import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, Container, Row, Table } from "reactstrap";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { format } from "date-fns";

import { userService } from "services/user.service";
import MyModalDialog from "./MyModalDialog";

const AffiliateSection = () => {
    const [data, setData] = useState([]);
    const [txs, setTxs] = useState([]);
    const navigate = useNavigate();
    const [showWithdrawDialog, setShowWithdrawDialog] = useState(false);
    const [alertWithdraw, setAlertWithdraw] = useState(false);
    const [withdrawalAmount, setWithdrawalAmount] = useState(null);
    const [totalCommission, setTotalCommission] = useState(0);
    const [userUpiId, setUserUpiId] = useState(null);
    const [errorType, setErrorType] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false);
    const minWithdrawalAmount = 500;

    useEffect(() => {
        fetchAffiliateStats();
    }, []);

    const fetchAffiliateStats = () => {
        userService.getAffiliateStats()
            .then((res) => {
                setData(res["data"]);
                setTotalCommission(res["total_commission"]);
                setTxs(res["txs"]);
            })
            .catch((err) => { })
    }

    const handleGameClick = (e, rowData) => {
        e.preventDefault();
        navigate("/game/" + rowData["uuid"]);
    }

    const enableWithdrawDialog = (e) => {
        e.preventDefault();
        if (!totalCommission) {
            setAlertWithdraw(true);
            return;
        }
        if (totalCommission < minWithdrawalAmount) {
            setAlertWithdraw(true);
            return;
        }
        setShowWithdrawDialog(true);
    }

    const handleWithdrawalAmountInput = (e) => {
        e.preventDefault();
        const val = parseInt(e.target.value);
        if (val === null || val === undefined || isNaN(val)) {
            setWithdrawalAmount('');
            return;
        }
        if (val > parseInt(totalCommission)) {
            return;
        }
        setWithdrawalAmount(val);
    }

    const handleUserUpiIdChanges = (e) => {
        e.preventDefault();
        setUserUpiId(e.target.value);
    }

    const initiateWithdrawalTx = (e) => {
        e.preventDefault();
        setErrorType(0);
        let val = parseInt(withdrawalAmount);
        if (val === null || val === undefined || isNaN(val) || val < minWithdrawalAmount || val > parseInt(totalCommission)) {
            setErrorType(1);
            return;
        }
        if (userUpiId === null || userUpiId === undefined || userUpiId.trim().length === 0) {
            setErrorType(2);
            return;
        }

        userService.withdrawAffiliateMoney(val, userUpiId)
            .then((res) => {
                setShowSuccess(true);
                setWithdrawalAmount(null);
                fetchAffiliateStats();
                setTimeout(() => {
                    setShowWithdrawDialog(false);
                    setShowSuccess(false);
                }, 2000);
            })
            .catch((err) => {
                alert(err);
            })
    }

    const tableData = () => {
        return (
            <Row style={{ justifyContent: "center" }}>
                <Col className='ml-auto mr-auto text-center'>
                    <Table className="winning-detail" style={{}}>
                        <thead>
                            <tr>
                                <th>
                                    Game
                                </th>
                                <th>
                                    User Name
                                </th>
                                <th>
                                    User Payment
                                </th>
                                <th>
                                    My Commission
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((rowData, index) => {
                                return (
                                    <tr>
                                        <td onClick={(e) => handleGameClick(e, rowData)}>
                                            {rowData["name"]}
                                            <p style={{ fontSize: "small" }}>(Team {rowData["team_number"]})</p>
                                        </td>
                                        <td>
                                            {rowData["first_name"]}
                                        </td>
                                        <td>
                                            ₹{rowData["total_price"]}
                                        </td>
                                        <td>
                                            ₹{rowData["total_price"] * rowData["commission"] / 100}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const printTime = (in_time) => {
        try {
            return (format(in_time, 'dd MMM h:mm a'));
        } catch (e) {
            return in_time;
        }
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" style={{ marginBottom: "30px", overflow: "scroll" }}>
                        {tableData()}
                    </Col>
                </Row>

                <h3 style={{ marginBottom: "0px" }}>My Transactions</h3>
                <Table borderless style={{ display: "block", overflow: "auto" }}>
                    <thead style={{ backgroundColor: "#320544", color: "white" }}>
                        <tr>
                            <th>
                            </th>
                            <th>
                                Amount
                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                                UPI Id
                            </th>
                            <th>
                                Time
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {txs.map((rowData, index) => {
                            return (
                                <tr>
                                    <th scope="row">
                                        {index + 1}
                                    </th>
                                    <th scope="row">
                                        ₹{rowData.amount}
                                    </th>
                                    <td>
                                        {rowData.tx_type}
                                    </td>
                                    <td>
                                        {rowData.user_upi_id}
                                    </td>
                                    <td>
                                        {printTime(rowData.created_at)}
                                    </td>
                                    <td>
                                        {rowData.tx_status}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

                <Row style={{ marginTop: "50px" }}>
                    <Col className="ml-auto mr-auto">
                        <p style={{ fontWeight: "bold" }}>Withdrawable Commission: ₹{totalCommission}</p>

                        <Button className='btn-round' color="info"
                            onClick={enableWithdrawDialog}
                            style={{ marginLeft: "1.25rem" }}>Withdraw</Button>
                    </Col>
                </Row>

                <Alert color="danger" isOpen={alertWithdraw}>
                    <Container>
                        Minimum ₹{minWithdrawalAmount} is required to make a withdrawal request.
                        <button
                            type="button"
                            className="close"
                            onClick={() => setAlertWithdraw(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>

            </Container>

            <MyModalDialog title="Withdraw your winnings" show={showWithdrawDialog} onClose={(e) => setShowWithdrawDialog(false)}>
                <div className="carousel-item active withdrawal">
                    <div>
                        <Row className="text-center content-center" style={{ margin: "auto", marginTop: "10px" }}>
                            <Col className="ml-auto mr-auto text-center">

                                <input type="number" placeholder="Withdrawal amount" value={withdrawalAmount} onChange={handleWithdrawalAmountInput}
                                    className={"ml-auto mr-auto text-center field center" + (errorType === 1 ? " error-input" : "")} style={{ textAlign: "center" }}></input>
                                <p>Min: <CurrencyRupeeIcon />{minWithdrawalAmount} - Max: <CurrencyRupeeIcon />1 lakh per day</p>

                                <h4 style={{ marginTop: "50px" }}>Winnings will be sent to</h4>
                                <input type="text" placeholder="Your UPI Id" value={userUpiId} onChange={handleUserUpiIdChanges}
                                    className={"ml-auto mr-auto text-center field center" + (errorType === 2 ? " error-input" : "")} style={{ textAlign: "center" }}></input>

                                <Col>
                                    <span style={{ textAlign: "center", fontWeight: "lighter", fontSize: "smaller" }}>Con10Craze is not responsible if you provided a wrong UPI ID.</span>
                                </Col>
                                <Button className="btn-round" size="lg" style={{ marginTop: "30px" }}
                                    color="danger"
                                    type="button"
                                    onClick={initiateWithdrawalTx}
                                >Withdraw Now</Button>
                                {showSuccess && <Row>
                                    <span style={{ fontWeight: "bold" }}>Congratulations, you should receive a payment from con10craze@okhdfcbank shortly. In case of any issue, tou can contact us via email at contact-us@con10craze.com.</span>
                                </Row>}
                            </Col>
                        </Row>
                    </div>
                </div>

            </MyModalDialog>

        </div>
    )
}

export default AffiliateSection;