import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import SEO from 'utils/seo';
import banner from "../assets/img/coins.webp"
import { useViewPort } from 'utils/helper';
import DarkFooter from 'components/Footers/DarkFooter';
import { Col, Container, Row } from 'reactstrap';
import games from "../assets/img/game.webp";


const levelsData = [
    { level: 1, unlocks: "Access to Basic Fantasy Sports Games" },
    { level: 3, unlocks: "Create Your Own Contests" },
    { level: 10, unlocks: "Access to High Stakes Games" },
    { level: 25, unlocks: "Create more than 2 teams in a contest" },
    { level: 100, unlocks: "Get more time to create your team(s)" },
    { level: 200, unlocks: "Earn bigger referral bonuses" },
    { level: 500, unlocks: "Boosters to double your winnings" },
    { level: 1000, unlocks: "Advanced tools to analyse the scores" },
];

const LevelUpInfo = () => {
    return (
        <div className="level-up-section text-center">
            <h3>How to Level Up</h3>
            <p>Increasing your level on Con10Craze is easy! Here’s how you can climb the ranks:</p>

            <div className="level-up-ways">
                <div className="level-up-way">
                    <h4>1. Game Participation</h4>
                    <p>
                        For every ₹20 you spend participating in games, you'll earn <strong>+1 level</strong>. The more you play, the higher you climb!
                    </p>
                </div>

                <div className="level-up-way">
                    <h4>2. Game Wins</h4>
                    <p>
                        Each time you win a game, you'll earn <strong>+1 level</strong>. Victory never felt so rewarding!
                    </p>
                </div>
            </div>

            <p className="level-up-note">
                The more games you play and win, the faster you can unlock exclusive rewards, features, and bonuses!
            </p>
        </div>
    );
};

const LevelsPage = () => {
    const [currentLevel, setCurrentLevel] = useState(0);
    const { width } = useViewPort();
    const breakpoint = 700;

    // Handle auto progression with interval
    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLevel < levelsData.length - 1) {
                setCurrentLevel(currentLevel + 1);
            }
        }, 3000); // Speed (3 seconds for each level)

        return () => clearInterval(interval);
    }, [currentLevel]);

    const Banner = () => {
        return (
            <div style={{ marginTop: "160px" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the banner in its container
                    }}
                // onClick={(e) => bannerActions[index](e)}
                >
                    <img
                        style={{
                            imageRendering: "crisp-edges",
                            height: (width > breakpoint) ? "auto" : "250px", // Let the height adjust automatically
                            maxWidth: (width > breakpoint) ? "60%" : "100%", // Make sure the image scales down on smaller screens
                            padding: "0 0px", // Optional: Extra padding around the image for more space
                        }}
                        src={banner} // Ensure this is a valid image URL
                        alt="Earn coins on Con10Craze"
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <IndexNavbar animate={false} />
            <div className="wrapper">
                <div className="main"></div>
                <div>
                    <SEO
                        title="Con10Craze Levels System – Unlock Rewards and Climb the Leaderboard"
                        description="Discover the Con10Craze Levels system, Level up by participating in fantasy games and winning big. Track your progress and see what's next as you reach higher levels and unlock new perks!"
                        type="website"
                        url="https://www.con10craze.com/levels" />

                    <Container  style={{
                        backgroundImage: `url(${games})`
                    }}>
                        <Row>
                            <Col className='ml-auto mr-auto'>

                                <div>
                                    <h2 className="title" style={{ marginTop: "150px" }}>Leveling Up Journey</h2>
                                    <p>
                                        Levels on Con10Craze represent your progress and achievements.<br></br>
                                        As you level up, you unlock exclusive rewards, features, and benefits.
                                    </p>
                                    <p>Ready to boost your level?</p>

                                    <LevelUpInfo />
                                </div>

                                <div className="levels-journey-container" style={{ marginTop: "50px" }}>

                                    {/* Road Background */}
                                    <div className="road-background">
                                        {/* Level Road Map */}
                                        {levelsData.map((level, index) => (
                                            <motion.div
                                                key={index}
                                                className={`level-box ${currentLevel >= index ? 'level-active' : ''}`}
                                                initial={{ opacity: 0, y: 100 }}
                                                animate={{ opacity: currentLevel >= index ? 1 : 0.05, y: currentLevel >= index ? 0 : 100 }}
                                                transition={{ duration: .5 }}
                                                style={{
                                                    top: `${index * 200}px`, // Vertically stagger levels
                                                    left: `${(index % 2 === 0 ? 0 : 50)}%`, // Stagger levels on the x-axis
                                                }}
                                            >
                                                <h2>Level {level.level}</h2>
                                                <p className="unlock-item">{level.unlocks}</p>
                                                <p className="level-description">{level.description}</p>
                                            </motion.div>
                                        ))}

                                        {/* Path Animation */}
                                        {/*                            {levelsData.map((_, index) => (
                                <motion.div
                                    key={`path-${index}`}
                                    className="level-path"
                                    initial={{ width: 0 }}
                                    animate={{ width: currentLevel >= index ? '100%' : '0%' }}
                                    transition={{ duration: 1.5, delay: 0.5 }}
                                    style={{
                                        top: `${index * 200 + 100}px`, // Place path between levels
                                        left: `${(index % 2 === 0 ? 30 : 80)}%`, // Match the x-axis of levels
                                    }}
                                />
                            ))}*/}

                                        {/* Car Animation */}
                                        {/*<motion.div
                                            className="car"
                                            initial={{ x: '0%', y: '0%' }}
                                            animate={{
                                                x: `${(currentLevel % 2 === 0 ? 20 : 70)}%`, // Car moves horizontally with levels
                                                y: `${currentLevel * 200}px`, // Move the car along the y-axis with levels
                                            }}
                                            transition={{ type: 'spring', stiffness: 200, duration: 1.5 }} // Synchronized car animation
                                        >
                                        </motion.div>*/}
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
                <DarkFooter />
            </div>
        </>
    );
};

export default LevelsPage;
