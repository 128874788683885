import { useTimer } from "react-timer-hook";
import { Alert } from "reactstrap";
import CircularProgress from '@mui/material/CircularProgress';
import { userService } from "services/user.service";
import { useEffect, useState } from "react";

export function isValidEmail(email) {
    //             const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return /\S+@\S+\.\S+/.test(email);
}

export function isValidString(input) {
    return (input !== undefined && input !== null && input.trim().length !== 0);
}

export function calculateTotalMoney(inUserData) {
    let total = 0;
    if ('account_unutilized' in inUserData && inUserData['account_unutilized'] > 0) {
        total = total + inUserData['account_unutilized'];
    }
    if ('bonus' in inUserData && inUserData['bonus'] > 0) {
        total = total + inUserData['bonus'];
    }
    if ('winnings' in inUserData && inUserData['winnings'] > 0) {
        total = total + inUserData['winnings'];
    }
    return total;
}

export function MyTimer({ expiryTimestamp }) {
    const {
        seconds, minutes, hours, days, isRunning, start, pause, resume, restart
    } = useTimer({
        expiryTimestamp,
    });

    return (<span>&nbsp;{days > 0 ? (days + "d"): ""} {hours > 0 ? (hours + "h"): ""} {minutes}m</span>);
}

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export function NotLoggedIn() {
    // TODO make this nice for 404 cool GIF

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div style={{ margin: "20px" }}>
                <Alert severity="error">
                    <h4>You need to login to view this resource!</h4>
                    <h6>Redirecting to Login page...</h6>
                    <CircularProgress />
                </Alert>
            </div>
        </div>

    )
}

// Function to get current date and time in IST
export function getCurrentDateInIST(inDate) {
  // Create a new Date object with the current time
  if (inDate === null || inDate === undefined) {
    inDate = new Date();
  }

  // Calculate the IST offset in milliseconds (IST is UTC+5:30)
  const istOffset = 5.5 * 60 * 60 * 1000;

  // Create a new Date object in IST by adding the IST offset to the UTC time
  const istDate = new Date(inDate.getTime() + (inDate.getTimezoneOffset() * 60000) + istOffset);

  return istDate;
};

export function showTodayStreakDialog() {
    const isLoggedIn = userService.isLoggedIn();
    const lastStreakTime = new Date(userService.getLastStreakTime());
    const lastStreakCross = new Date(userService.getStreakCross());
    const todayDate = getCurrentDateInIST();
    lastStreakTime.setHours(0,0,0,0);
    todayDate.setHours(0,0,0,0);

    // Check if the streak needs to be completed today
    return isLoggedIn && lastStreakTime && lastStreakTime.getTime() !== todayDate.getTime() && (!lastStreakCross || lastStreakCross.getTime() != todayDate.getTime());
}

export function showWhatsappDialog() {
    const isLoggedIn = userService.isLoggedIn();
    const lastWhatsappCross = new Date(userService.getWhatsappCross());
    const todayDate = getCurrentDateInIST();
    todayDate.setHours(0,0,0,0);

    // Check if the streak needs to be completed today
    return isLoggedIn && (!lastWhatsappCross || lastWhatsappCross.getTime() !== todayDate.getTime());
}

export function useViewPort() {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return { width };
}